import styled from 'styled-components'

export const ContainerContentArticle = styled.div`
    width: 100%;
    position: relative;
    padding-top: 75px;
    display: flex;
    flex-direction: column;

    background: #fff;
`

export const ArticleSelect = styled.div`
    position: fixed;
    top: 40%;
    transition: all 0.2s ease-in-out;
    opacity: ${(props) => (props.visible ? '1' : '0')};
    color: #000;
    z-index: 12;
    padding-inline: 70px;

    div {
        h3 {
            max-width: 220px;
            line-height: 80px;

            font-family: 'RocGroteskCompressed';
            font-weight: bold;
            font-size: 80px;
            letter-spacing: 2px;

            text-transform: uppercase;
        }

        a {
            display: flex;
            align-items: center;
            gap: 16px;

            font-size: 14px;
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;

            color: #742698;
            & img {
                width: auto;
                opacity: 0.4;
            }
        }
    }

    @media (max-width: 1024px) {
        position: initial;

        div {
            width: 70%;
            display: flex;
            flex-direction: column;

            h3 {
                width: 100%;
                max-width: 300px;
                line-height: 40px;
            }

            a {
                display: none;
            }
        }
    }

    @media (max-width: 850px) {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.6rem;

        div {
            width: 100%;
            h3 {
                font-family: 'RocGroteskCompressed';
                font-size: 75px;
                font-weight: bold;
                color: #000;
                line-height: 65px;
                padding: 1rem 0 1rem 0;
                z-index: 11;
                word-break: break-word;
                text-align: center;
            }
        }
    }
`

export const ContentArticle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;

    position: relative;
    z-index: 11;

    padding-right: 50px;

    color: #000;

    .block {
        display: block;
    }

    .content {
        width: 70%;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .paragraph-big {
        width: 100%;
        max-width: 800px;
        font-size: 30px;
    }

    .box-paragraph {
        display: flex;
        flex-direction: column;
        gap: 10px;

        strong {
            width: 100%;
            max-width: 500px;
            font-size: 19px;
        }

        p {
            width: 100%;
            max-width: 500px;
            font-family: CabinetGrotesk;
            font-size: 20px;
            font-weight: 500;
        }
    }

    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        padding-inline: 45px;
    }

    @media (max-width: 850px) {
        .content {
            width: 100%;

            & > img {
                width: 900px;
            }
        }
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }
`

export const ContainerShare = styled.div`
    width: 80%;
    max-width: 920px;
    height: 100px;
    z-index: 11;
    color: #000;
    
    @media (max-width: 1024px) {
        width: 70%;
    }

    @media (max-width: 850px) {
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 450px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

export const ContentHtml = styled.div`
    width: 70%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .Introduction {
        margin-bottom: 39px;

        h3 {
            font-size: 2rem;
            margin-bottom: 1.25rem;
            line-height: 2.5rem;
        }

        p {
            width: 100%;
            max-width: 800px;
            font-size: 1.875rem;
        }
    }

    .boxContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 1.875rem;

        & > strong {
            width: 100%;
            max-width: 500px;
            font-size: 1.188rem;
            display: block;
        }

        p {
            width: 100%;
            max-width: 560px;
            word-break: break-word;

            font-family: CabinetGrotesk;
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    @media (max-width: 1024px) {
        width: 100%;
        display: flex;
        align-items: center;
    }

    @media (max-width: 850px) {
        width: 100%;

        & > img {
            width: 900px;
        }

        .Introduction {
            p {
                font-size: 1.875rem;
                line-height: 2.5rem;
            }
        }

        .boxContent {
            p {
                width: 100%;
                max-width: 500px;
            }
        }
    }
`

export const Paragraph = styled.p`
    width: 90%;
    max-width: 780px;
    position: relative;

    font-family: CabinetGrotesk;
    font-size: 400;
    font-size: 25px;

    div {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 850px) {
        .content {
            width: 100%;

            & > img {
                width: 900px;
            }
        }

        .box-paragraph {
            width: 100%;
            max-width: 500px;
        }
    }
`
