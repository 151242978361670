import React from 'react';
import { useSwiper } from 'swiper/react';

import { ContainerArrow, ArrowButton, ArrowButtonLeft } from '../styles';

import ArrowRightIcon from '../../../assets/icons/arrow.svg'
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg'


export default function SlideNextButton() {
  const swiper = useSwiper();
  return (
    <ContainerArrow>
      <ArrowButtonLeft
        onClick={() => swiper.slidePrev()}
      >
        <img src={ArrowLeftIcon} alt="Seta para a esquerda"/>
      </ArrowButtonLeft>
      <ArrowButton
        className="swiper-nav-btns"
        onClick={() => swiper.slideNext()}
      >
        <img src={ArrowRightIcon} alt="Seta para a direita" />
      </ArrowButton>
    </ContainerArrow>
  );
}