import React from 'react'

// styles
import * as S from './styles'

// i18n
import { i18n } from '../../translate/i18n'

export function EstructurePageCards({
    title,
    titlePt,
    subtitlePt,
    subtitle,
    descriptionPt,
    description,
    descriptionTwo,
    descriptionTwoPt,
    className,
    children,
}) {
    i18n.addResourceBundle('en', 'translations')
    i18n.addResourceBundle('pt', 'translations')

    return (
        <S.Space className={className}>
            <S.DBTitleBox className="title-box">
                <S.DBMainTitle>
                    {i18n.language === 'pt-BR' ? titlePt : title}
                </S.DBMainTitle>
                <S.DBDescription>
                    {i18n.language === 'pt-BR' ? subtitlePt : subtitle}
                </S.DBDescription>
            </S.DBTitleBox>
            {children}
            <S.BoxTexts className="box-texts">
                <S.DBAbout>
                    <S.DBParagraph fontWeight="600">
                        {i18n.language === 'pt-BR'
                            ? descriptionPt
                            : description}
                    </S.DBParagraph>
                </S.DBAbout>
                <S.DBAbout>
                    <S.DBParagraph fontWeight="300">
                        {i18n.language === 'pt-BR'
                            ? descriptionTwoPt
                            : descriptionTwo}
                    </S.DBParagraph>
                </S.DBAbout>
            </S.BoxTexts>
        </S.Space>
    )
}
