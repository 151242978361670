import styled, { css, keyframes } from 'styled-components'
import { Swiper } from 'swiper/react'

// keyframes
const menuAnimation = (width, padding) => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${width};
    padding-left: ${padding};
  }
`

const menuCloseAnimation = keyframes`
  from {
    width: 300px;
  }
  to {
    width: 0px;
  }
`

const menuHamburguerAnimation = keyframes`
  from {
    width: 5px;
  }
  to {
    width: 25px;
  }
`

const firstOpenAnimation = css`
    animation: 0.2s linear ${menuAnimation('343px', '73px')} forwards;

    .list-menu,
    hr {
        transition: all 0.2s ease-in-out;
        opacity: 1;
    }

    .menu-hamburguer div {
        animation: ${menuHamburguerAnimation} 0.2s;
        width: 25px;
    }

    @media(max-width: 1024px) {
        animation: 0.2s linear ${menuAnimation('315px', '45px')} forwards;
    }
`

const SecondOpenAnimation = css`
    animation: 0.2s linear ${menuCloseAnimation} forwards;
`

export const ContainerMenuDynamic = styled.div`
    width: 100%;
    height: 600px;

    margin-top: 83px;
    margin-bottom: 160px;

    display: flex;
    gap: 80px;

    overflow: auto;

    @media (max-width: 700px) {
        margin-bottom: 0px;
    }
`

export const Header = styled.div`
    width: 100%;
    height: calc(100vh - 5.25rem);
    border-radius: 200px;

    background: blue;
    overflow: hidden;

    img {
        margin-top: -30px;
    }
`

export const ContentMenu = styled.div`
    height: 100%;

    overflow: hidden;
    transition: all 0.5s ease-in-out;
    padding: 0px 20px;

    hr {
        width: 100%;
        height: 1px;

        border: none;
        background-color: gray;
    }

    .list-menu,
    hr {
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }

    ${({ isOpenMenu }) =>
        isOpenMenu !== null &&
        (isOpenMenu ? firstOpenAnimation : SecondOpenAnimation)}

    @media(max-width: 700px) {
        display: none;
    }
`

export const MenuHamburguer = styled.div`
    width: 25px;

    display: flex;
    flex-direction: column;
    gap: 2px;

    margin-bottom: 50px;

    div {
        width: 5px;
        height: 5px;
        background: #fff;
    }
`

export const ContainerMenuArtitles = styled.div`
    display: flex;
    flex-direction: column;

    padding-block: 20px 60px;
`

export const ContainerSections = styled.div`
    padding-block: 10px;
`

export const ListMenu = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const ItemMenuBase = styled.li`
    width: 300px;
    font-size: 1.3rem;
    font-weight: 300;

    letter-spacing: 0.63px;
    list-style: none;
    transition: all linear 0.2s;
    color: #ffffff;

    a {
        font-family: 'CabinetGrotesk';
        font-size: 25px;

        text-decoration: none;
        color: #ffffff;
        margin-left: 10px;
    }

    ${(props) => {
        if (props.isActive) {
            return css`
                font-weight: 700;
            `
        } else {
            return css`
                &:hover {
                    opacity: 0.8;
                }
            `
        }
    }}
`

export const ButtonMenu = styled.button`
    text-decoration: none;
    color: #ffffff;
    border: none;
    background: transparent;

    font-family: ${(props) =>
        props.isActive ? 'roc-grotesk' : 'roc-grotesk-light'};
    font-size: 1.3rem;
    font-weight: ${(props) => (props.isActive ? '900' : '300')};
    letter-spacing: 0.63px;
    list-style: none;

    cursor: pointer;
`

export const ItemMenuArtitles = styled(ItemMenuBase)`
    text-transform: uppercase;
`

export const ContainerSlidersArtitles = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 700px) {
        padding-left: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }
`

export const ContentSectionArticle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 100px;
    position: relative;

    @media ${(props) => props.theme.medias.lg} {
        margin-bottom: 55px;
    }
`

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px 10%;

    padding-right: 70px;

    h2 {
        font-family: 'roc-grotesk-condensed-600';
        font-weight: 600;
        font-size: 60px;
        letter-spacing: 1.5px;

        text-transform: uppercase;
    }

    p {
        flex: 1;
        max-width: 475px;
        font-family: 'CabinetGrotesk';
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #747474;
        opacity: 0.4;
    }

    @media (max-width: 1100px) {
        flex-wrap: wrap;
        h2,
        p {
            width: 100%;
        }
    }
    @media (max-width: 700px) {
        gap: 0px;
        padding-right: 0px;
    }

    @media (max-width: 400px) {
        h2 {
            font-size: 35px;
        }

        p {
            font-size: 1.1rem;
        }
    }
`

export const SwiperContainer = styled(Swiper)`
    width: 100%;

    a {
        text-decoration: none;
        color: #fff;
    }

    @media (max-width: 600px) {
        .next {
            background: transparent
                linear-gradient(180deg, #0b161700 0%, #0b1617cf 100%) 0% 0%
                no-repeat padding-box;
            opacity: 0.5;
        }
    }
`

export const ContainerSlide = styled.div`
    position: relative;
    width: 100%;
    /* ${(props) => props.width && props.width + 'px'}; */
    flex-wrap: nowrap;
    display: flex;
    overflow: auto;
    z-index: 11;

    transition: all 0.3s ease-in-out;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    img {
        object-fit: cover;
    }

    a {
        text-decoration: none;
        color: #fff;
    }
    //má pratica necessaria para manipulação do swipper(lib)
    div {
        div {
            div {
                @media ${(props) => props.theme.medias.lg} {
                    word-break: break-word;
                    h3 {
                        width: 90%;
                    }
                }
                width: 420px !important;
            }
        }
    }

    & .swiper,
    swiper-container {
        position: unset;
        margin-left: 0;
    }
`

export const ContainerArrow = styled.div`
    position: absolute;
    width: 90px;
    top: 0;
    right: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(270deg, #0b1617 0%, #0b161700 100%)
    0% 0% no-repeat padding-box;
    opacity: 1;
    z-index: 12;
    transition: all 0.3s ease-in-out;
`
export const ArrowButton = styled.button`
    border: none;
    cursor: pointer;
    z-index: 13;
    background: transparent;

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    &:active {
        img {
            transform: scale(0.8);
        }
    }

    img {
        transition: all 0.3s ease-in-out;
        margin-top: 50px;
        width: 59px;
        height: 50px;
    }
`

export const ArrowButtonLeft = styled.button`
    border: none;
    cursor: pointer;
    z-index: 13;
    background: transparent;

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    &:active {
        img {
            transform: scale(0.8);
        }
    }

    img {
        transition: all 0.3s ease-in-out;
        width: 59px;
        height: 50px;
        background: transparent;
    }
`
