import styled from 'styled-components'
import { Wrapper } from '../../styles/Wrapper'

export const ContainerCoverArticle = styled(Wrapper)`
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding-top: calc(50px + 5.5rem);
    padding-bottom: 50px;
    background: ${(props) =>
        props.width <= 768
            ? props.backgroundMobile && `url(${props.backgroundMobile})`
            : props.background
            ? props.background
            : `url(${props.backgroundCover})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    /* animation: ${(props) =>
        props.theme.animations.showImage} 0.3s ease-in-out; */

    @media (max-width: 1440px) {
        padding-top: calc(50px + 4.625rem);
        padding-bottom: 50px;
    }
`

export const Shadow = styled.div`
    height: 65vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(57, 57, 57, 0.608) 47%,
        rgb(23, 23, 23) 100%
    );
`

export const BoxImageCenter = styled.div`
    width: 100%;
    max-width: 364px;
    height: auto;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    img {
        width: 100%;
        height: auto;
    }

    @media (max-height: 600px) {
        height: auto;

        display: flex;
        justify-content: center;

        img {
            width: 60%;
            object-fit: cover;
        }
    }

    @media (min-height: 600px) {
        @media (max-width: 500px) {
            width: 80%;
            max-width: 328px;
            height: auto;
        }
    }
`

export const ContentCover = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;

    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div > a {
        width: 400px;
        height: 400px;
        fill: #c2de23;
        transition: 0.3s ease-out;
        &:hover {
            fill: #000;
        }
        &::before {
            transition: width 1000ms;
        }
        img {
            width: 100%;
            max-width: 204px;

            :hover {
                fill: red;
                stroke: red;
            }
        }
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

export const BoxInfoArticle = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.onLoaded ? '1' : '0')};
    z-index: 5;

    .title {
        width: 100%;
        max-width: 800px;
        position: fixed;
        bottom: 100px;

        font-family: RocGroteskCompressed, sans-serif;
        font-size: 100px;
        font-weight: bold;

        line-height: 84px;
        text-transform: uppercase;
    }

    @media (max-height: 600px) {
        .title {
            width: 100%;
            max-width: 600px;
            font-size: 70px;
            line-height: 60px;
        }
    }

    @media (min-height: 600px) {
        @media (max-width: 700px) {
            .title {
                width: 100%;
                max-width: 660px;

                font-size: 85px;
                line-height: 70px;
                letter-spacing: 2px;
            }
        }

        @media (max-width: 400px) {
            .title {
                width: 100%;
                max-width: 380px;
                font-size: 85px;
                line-height: 80px;
                letter-spacing: 2px;
            }
        }

        @media (max-width: 370px) {
            .title {
                width: 95%;
                max-width: 380px;
                font-size: 65px;
                line-height: 60px;
                letter-spacing: 2px;
            }
        }
    }

    @media (max-width: 690px) {
        .title {
            padding-right: 23px;
        }
    }
`

export const BoxTypeArticle = styled.div`
    display: flex;
    gap: 8px;

    button {
        background: none;
        font-family: CabinetGrotesk;
        font-weight: 300;
        font-size: 1.188rem;
        border: 1px solid #c2de23;
        border-radius: 16px;
        padding: 2px 8px;
        color: #c2de23;
        :hover {
            cursor: pointer;
        }
    }
`
