import styled from 'styled-components'

export const Container = styled.div`
    min-width: 396px;
    max-width: 396px;
    height: 396px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h3 {
        transition: all 0.3s ease-in-out;
    }
    @media ${(props) => props.theme.medias.lg} {
        height: auto;
    }
`

export const CoverArtitle = styled.div`
    width: 100%;
    height: 350px;

    display: flex;
    align-items: flex-end;

    padding: 30px;

    cursor: pointer;
    position: relative;
    background: ${(props) => props.background};

    background-image: ${(props) =>
        props.imageCover &&
        `linear-gradient(180deg, #FFFFFF00 0%, #3939399B 47%, #171717 100%), url(${props.imageCover})`};
    background-position: center;
    background-size: cover;
    animation: ${(props) => props.theme.animations.showImage} 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    img {
        width: 100%;
        height: 100%;

        object-fit: cover;

        position: absolute;
        right: 0;
        top: 0;
    }

    h3 {
        font-family: CabinetGrotesk;
        font-weight: 400;
        font-size: 2rem;
        z-index: 3;

        line-height: 35px;
        text-align: left;
    }

    &:hover {
        background-image: ${(props) =>
            props.imageCover &&
            `linear-gradient(180deg, #FFFFFF00 0%, #3939399B 40%, #171717 100%), url(${props.imageCover})`};
        h3 {
            margin-bottom: 13%;
        }
    }

    @media (max-width: 980px) {
        h3 {
            font-size: 1.5rem;
            line-height: 40px;
        }
    }

    @media (max-width: 800px) {
        height: ${({ isOpenMenu }) => (isOpenMenu ? '300px' : '350px')};
        h3 {
            font-size: ${({ isOpenMenu }) =>
                isOpenMenu ? '1.1rem' : '1.3rem'};
            line-height: ${({ isOpenMenu }) => (isOpenMenu ? '20px' : '40px')};
        }
    }

    @media (max-width: 568px) {
        h3 {
            font-size: 2rem;
            line-height: 30px;
        }
    }

    @media (max-width: 420px) {
        height: 300px;
        background-image: ${(props) =>
            props.imageCoverMobile &&
            `linear-gradient(180deg, #FFFFFF00 0%, #3939399B 47%, #171717 100%), url(${props.imageCoverMobile})`};
        h3 {
            font-size: 1.5rem;
            line-height: 30px;
        }
    }
`

export const BoxTypeArticle = styled.div`
    display: flex;
    gap: 8px;

    span {
        font-family: CabinetGrotesk;
        font-weight: 400;
        font-size: 0.875rem;

        border: 1px solid #c2de23;
        border-radius: 12px;
        padding: 0px 8px;

        color: #c2de23;
    }
`
