import React from 'react'

import IconAnimation1sti from '../IconAnimation1sti'
import { DateSharePrint } from '../DateSharePrint'
import { ArticleSelect } from '../ArticleSelect'

// Styles
import * as S from './styled'

// i18
import { i18n } from "../../translate/i18n"


const ContentArticle = ({
    children,
    title,
    titleEn,
    author,
    linkLinkedin,
    className,
    date,
    dateEn,
    content,
    contentEn
}) => {

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <S.ContainerContentArticle className={className}>
            <ArticleSelect 
                title={title}
                titleEn={titleEn}
                author={author}
                linkLinkedin={linkLinkedin}
            />
            <S.ContentArticle>
                <div className="content">
                    <S.ContainerShare>
                        <DateSharePrint
                            date={date}
                            dateEn={dateEn}
                        />
                    </S.ContainerShare>
                    <S.ContentHtml
                        dangerouslySetInnerHTML={{
                            __html: isLanguangePt ? content : contentEn,
                        }}
                    />
                    <DateSharePrint 
                        hasDate={false}
                    />
                    {children}
                </div>
            </S.ContentArticle>
            {content && <IconAnimation1sti />}
        </S.ContainerContentArticle>
    )
}

export default ContentArticle