import styled from 'styled-components'
import Wrapper from '../../../components/Wrapper'

import { Link as GatsbyLink } from 'gatsby'

export const CTAContainer = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    cursor: pointer;
    @media (max-width: 768px) {
        font-size: 0.85rem;
        background: #0b1617;
    }
    & .ChangesTexts {
        animation-name: OpacityTexts;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        @keyframes OpacityTexts {
            0% {
                opacity: 1;
                transform: translateX(0px);
            }
            100% {
                transform: translateX(-50px);
                opacity: 0;
            }
        }
    }
`

export const BGVideo = styled.video`
    position: absolute;
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
`
export const BGVideoMobile = styled.video`
    display: none;
    position: absolute;
    top: 4.625rem;
    width: 100%;
    @media (max-width: 768px) {
        display: block;
    }
`
export const ShadowMobile = styled.div`
    display: none;
    pointer-events: none;
    background: transparent linear-gradient(180deg, #0b161700 0%, #0b1617 100%)
        0% 0% no-repeat padding-box;
    width: 100%;
    height: 135px;
    position: absolute;
    top: 50vw;
    @media (max-width: 768px) {
        display: block;
    }
    @media (max-width: 550px) {
        top: 45vw;
    }
`
export const ImgMob = styled.img`
    margin-top: 4.625rem;
    width: 100%;
    @media (min-width: 768px) {
        display: none;
    }
`
export const CTAWrapper = styled.section`
    width: 100%;
    font-size: 1.25rem;
    margin-top: 4.625rem;
    padding-top: 3.2rem;
    height: 51.5vw;
    background: transparent
        linear-gradient(270deg, #ffffff00 0%, #0b1617a8 66%, #0b1617 100%) 0% 0%
        no-repeat padding-box;
    @media (max-width: 768px) {
        background: transparent;
    }
    animation-name: NoTexts;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes NoTexts {
        0% {
            opacity: 0;
            transform: translateX(-50px);
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    @media (min-width: 768px) {
        margin-top: 4.8rem;
        display: flex;
        align-items: center;
        padding-top: 0;
    }
    @media (max-width: 768px) {
        height: 100vh;
    }
    h1,
    h3 {
        width: 100%;
    }
    @media (min-width: 1440px) {
        h1,
        h3 {
            max-width: 45vw;
        }
    }
    @media (max-width: 1200px) {
        h1,
        h3 {
            /* max-width: 60vw; */
        }
    }
    @media (max-width: 1024px) {
        h1,
        h3 {
            /* max-width: 75vw; */
        }
    }
    @media (max-width: 950px) {
        h1,
        h3 {
            max-width: 85vw;
        }
    }
    @media (max-width: 768px) {
        height: auto;
        margin-top: 20rem;
        padding: 0;
        h1 {
            padding: 0;
        }
    }
    @media (max-width: 400px) {
        margin-top: 74%;
    }

    @media (min-height: 1100px) {
        display: flex;
        align-items: center;
        padding-block: 0;
        margin-top: 5.5rem;
    }
`

export const CTAContent = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: 5%;
    @media (max-width: 768px) {
        margin-top: 11%;
        gap: 20px;
        padding: 0 1rem 3rem 1rem;
    }

    @media (min-width: 768px) {
        margin-top: 0;
    }
`
export const BoxTitleHome = styled.section`
    @media (max-width: 400px) {
        width: 100%;
    }
`
export const PortugueseTitle = styled.h1`
    font-size: 90px;
    line-height: 70px;
    text-shadow: 0px 0px 10px #00000081;

    &:nth-child(1) {
        margin-bottom: 35px;
    }

    @media (max-width: 450px) {
        font-size: 43px;
        line-height: 50px;
        
    }
`
export const EnglishTitle = styled.h1`
    font-size: 90px;
    line-height: 70px;
    text-shadow: 0px 0px 10px #00000081;

    &:nth-child(1) {
        margin-bottom: 35px;
    }

    @media (max-width: 450px) {
        font-size: 40px;
        line-height: 50px;

        &:nth-child(1) {
        margin-bottom: 10px;
    }
    }
`
export const CTATextBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    @media (max-width: 1024px) {
        padding-top: 1.5rem;
    }
    @media (max-width: 768px) {
        width: 90vw;
        padding-top: 0.7rem;
    }
`
export const BoxTextAbout = styled.section`
    width: 45%;

    @media(max-width:450px) {
        width: 95%;
    }
`
export const CTAAbout = styled.h3`
    text-shadow: 0px 0px 10px #00000081;
    line-height: 2.5rem;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);

    font-size: 30px;
    font-weight: 500;
    max-width:500px;
    @media (max-width: 950px) {
        font-size: 18px;
        line-height: 26px;
        padding-bottom: 0.5rem;
        width:600px;
    }
    @media (max-width: 450px) {
        font-size: 16px;
        line-height: 26px;
    }
`
export const CTAOptions = styled.div`
    padding-top: 3.2rem;
    @media (max-width: 1024px) {
        padding-top: 2rem;
    }
    @media (max-width: 768px) {
        width: 400px;
    }
    @media (max-width: 450px) {
        width: 300px;
        padding-top: 1rem;
    }
`
export const Div = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width:1160px){
        height: 130px;
        margin-bottom: 30px;
    }

    @media(max-width:768px){
        margin-bottom: 0px;
    }
`

export const ButtonOne = styled(GatsbyLink)`
    transition: 0.4s;
    display: flex;
    text-decoration: none;
    color: black;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 59px;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 17px;
    font-family: CabinetGrotesk;
    font-weight: 500;
    padding: 16px;
    &:hover {
        cursor: pointer;
        background-color: #0b1617;
        color: #fff;
    }
    @media (max-width: 600px) {
        text-align: left;
    }
`
export const ButtonTwo = styled.button`
    width: fit-content;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-size: 14px ;
    font-family: CabinetGrotesk;
    font-weight: 500;
    color: #fff;
    opacity: 0.4;
    padding: 0;

    img{
        width: 10px ;
        margin-left: 5px;
    }
    &:hover {
        cursor: pointer;
    }
`
