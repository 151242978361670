import React from "react"

import Layout from "../components/Layout"

// assets
import image404 from "../assets/images/404_img.png"

// styled
import styled from "styled-components"
import { i18n } from "../translate/i18n"
import { Link } from "gatsby"

const Box404 = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  height: calc(100vh - 16.6rem);

  img {
    width: 260px;
    height: 260px;
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 2.8rem;
  }

  @media (max-width: 768px) {
    height: 100%;
    padding-top: 5.8rem;
    img {
      width: 200px;
      height: 200px;
    }
    h1 {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.8rem;
    }
  }
`

const WorkButton = styled.button`
  width: 9.5rem;
  height: 59px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-family: CabinetGrotesk;
  font-size: 17px;
  font-weight: 500;
  color: #171717;
  margin-top: 2rem;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: #0b1617;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

const NotFoundPage = () => (
  <Layout seoTitleTitle="404: Not found">
    <Box404>
      <img src={image404} alt="404" />
      <h1
        style={{
          marginTop: "2rem",
        }}
      >
        {i18n.language === "pt-BR" ? (
          <h1>
            Página não <h1>encontrada</h1>
          </h1>
        ) : (
          <h1>
            Page not <h1>found</h1>
          </h1>
        )}
      </h1>
      <Link to="/">
        <WorkButton>
          {i18n.language === "pt-BR" ? "Voltar ao início" : "Back to start"}
        </WorkButton>
      </Link>
    </Box404>
  </Layout>
)

export default NotFoundPage
