import styled from 'styled-components'

export const SocialContainer = styled.div`
    width: 167px;
    height: 43px;

    position: absolute;
    top: 32px;
    display: flex;
    gap: 16px;
    padding: 10px 14px;

    border: 1px solid #000000;
    opacity: 1;

    img {
        width: 21px;
        height: 21px;
    }
`

export const LinkTo = styled.a`
    text-decoration: none;
`
export const SocialShareButton = styled.button`
    display: flex;
    gap: 10px;
    border: none;
    background: transparent;

    opacity: 1;

    cursor: pointer;

    p {
        text-align: left;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #0b1617;
    }

    img {
        width: 17px;
        height: 17px;
    }
`

export const BoxShare = styled.div`
    display: flex;
    flex-direction: column;
`

export const SocialArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`
