import styled from 'styled-components'

export const ContainerSection = styled.div`
    width: 100%;
`

export const Description = styled.div`
    display: flex;
    gap: 44px;
    padding-bottom: 80px;

    p {
        width: 100%;
        max-width: 395px;

        font-size: 16px;
        font-family: 'CabinetGrotesk';
        font-weight: 400;

        color: #171717e6;
    }

    img {
        width: 134px;
        height: 134px;
        /* border-radius: 999px;
        object-fit: cover;
        object-position: top; */
    }

    @media (max-width: 1024px) {
        padding-inline: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }

    @media (max-width: 548px) {
        flex-wrap: wrap;
        justify-content: center;

        p {
            width: 100%;
            max-width: 255px;
        }
    }
`
export const SocialContainer = styled.div`
    width: 167px;
    height: 43px;

    position: absolute;
    top: 32px;
    display: flex;
    gap: 16px;
    padding: 10px 14px;

    border: 1px solid #000000;
    opacity: 1;

    img {
        width: 21px;
        height: 21px;
    }
`

export const LinkTo = styled.a`
    text-decoration: none;
`
export const SocialShareButton = styled.button`
    display: flex;
    gap: 10px;
    border: none;
    background: transparent;

    opacity: 1;

    cursor: pointer;

    p {
        text-align: left;
        text-decoration: underline;
        font-size: 15px;
        letter-spacing: 0px;
        color: #0b1617;
    }

    img {
        width: 17px;
        height: 17px;
    }
`

export const SocialArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 83px;
`
