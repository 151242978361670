import React from 'react'

//components
import Layout from '../../components/Layout'
import { SectionArticleTexts } from '../../components/SectionArticleTexts'
import ArticleTypeTwo from '../../components/ArticleLayout'
import ArticlePageThree from '../../components/ArticlePageThree'
import NotFoundPage from '../../pages/404'

const BinocularArticles = ({ pageContext }) => {
    const pageType = pageContext.typePage
    let component

    switch (pageType) {
        case 'firguringOut':
            component = <SectionArticleTexts pageContext={pageContext} />
            break
        case 'zoom':
            component = <SectionArticleTexts pageContext={pageContext} />
            break
        case 'thinkingBeyond':
            component = <ArticleTypeTwo pageContext={pageContext} />
            break
        case 'technicolor':
            component = <ArticlePageThree pageContext={pageContext} />
            break
        default:
            return <NotFoundPage />
    }

    return (
        <Layout
            seoTitle={pageContext?.titleArticle}
            seoImage={pageContext?.backgroundCover?.url}
        >
            {component}
        </Layout>
    )
}

export default BinocularArticles
