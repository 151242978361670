import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// styles
import * as S from './styles'

// i18n
import { i18n } from '../../translate/i18n'

//components
import Button from '../Button'

export function ContentServive({ dataService }) {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                services {
                    cards
                    knowMore
                    knowMorePt
                }
            }
        }
    `)

    i18n.addResourceBundle('en', 'translations', dataService)
    i18n.addResourceBundle('pt', 'translations', dataService)

    const services = data.firsti.services[0]

    return (
        <S.DBContainer>
            <S.DBHeader>
                <S.DBMainTitle language={i18n.language === 'pt-BR'}>
                    {i18n.language === "pt-BR"
                        ? dataService.titlePt
                        : dataService.title}
                </S.DBMainTitle>
                <S.DBWrapper>
                    <S.Space>
                        <S.DBTitleBox>
                            <S.DBDescription>
                                {i18n.language === 'pt-BR'
                                    ? dataService.subtitlePt
                                    : dataService.subtitle}
                            </S.DBDescription>
                        </S.DBTitleBox>
                        {i18n.language === "pt-BR" ? (
                            <S.ImageMobile
                                src={dataService.imageMobPt.url}
                                alt="Image Strategy"
                            />
                        ) : (
                            <S.ImageMobile
                                src={dataService.imageMobEn.url}
                                alt="Image Strategy"
                            />
                        )}

                        <S.Texts
                            className="texts"
                            dangerouslySetInnerHTML={{
                                __html: `${i18n.language === "pt-BR" ? dataService.textsPt.html : dataService.texts.html}`,
                            }}
                        />
                    </S.Space>
                    {i18n.language === "pt-BR" ? (
                        <S.DBImageBox>
                            <img
                                src={dataService.imagePt.url}
                                alt="Image Strategy"
                            />
                        </S.DBImageBox>
                    ) : (
                        <S.DBImageBox>
                            <img
                                src={dataService.imageEn.url}
                                alt="Image Strategy"
                            />
                        </S.DBImageBox>
                    )}
                </S.DBWrapper>
            </S.DBHeader>

            <S.DBContentContactUs>
                <S.DBContentContactUsWrapper>
                    <S.DBContentContactUsTitle>
                        <h2>
                            {i18n.language === "pt-BR"
                                ? dataService.titleContactUsPt
                                : dataService.titleContactUs}
                        </h2>
                    </S.DBContentContactUsTitle>
                    <Button to="/contact">
                        {i18n.language === "pt-BR"
                            ? dataService.textButtonContactUsPt
                            : dataService.textButtonContactUs}
                    </Button>
                </S.DBContentContactUsWrapper>
                <S.ContentListCards>
                <S.ListCard>
                    {i18n.language === "pt-BR"
                        ? services.cards.cardPt.map((item, i) => {
                                const serviceCurrent =
                                    item.title === dataService.titlePt
                                return (
                                    <S.Card
                                        key={i}
                                        id={item.id}
                                        href={item.link}
                                        invisible={serviceCurrent}
                                    >
                                        <S.Title>{item.title}</S.Title>
                                        <S.DescriptionCard>
                                            {item.subtitle}
                                        </S.DescriptionCard>
                                        <S.LinkKnowMore>
                                            <a href={item.link}>
                                                {services.knowMorePt}
                                            </a>
                                            <img
                                                src={dataService.iconPlus.url}
                                                alt="Icon Plus"
                                            />
                                        </S.LinkKnowMore>
                                    </S.Card>
                                )
                            })
                        : services.cards.card.map((item, i) => {
                                const serviceCurrent =
                                    item.title === dataService.title
                                return (
                                    <S.Card
                                        key={i}
                                        id={item.id}
                                        href={item.link}
                                        invisible={serviceCurrent}
                                    >
                                        <S.Title>{item.title}</S.Title>
                                        <S.DescriptionCard>
                                            {item.text}
                                        </S.DescriptionCard>
                                        <S.LinkKnowMore>
                                            <a href={item.link}>
                                                {services.knowMore}
                                            </a>
                                            <img
                                                src={dataService.iconPlus.url}
                                                alt="Icon Plus"
                                            />
                                        </S.LinkKnowMore>
                                    </S.Card>
                                )
                            })}
                    </S.ListCard>
                </S.ContentListCards>
            </S.DBContentContactUs>
        </S.DBContainer>
    )
}
