import React, { useState } from 'react'

// Icons
import Isti from '../../assets/icons/1sti.svg'
import { Bin } from '../../assets/icons/Bin.jsx'

import * as S from './styled'

const IconAnimation1sti = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <S.BoxIconAnimation>
            <S.IconWrapper
                onMouseOver={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
            
                <S.CircleOne className="one">
                    {isOpen && <Bin />}
                </S.CircleOne>
                <S.CircleTwo isOpen={isOpen} className="two">
                    <img src={Isti} alt="logo 1sti" />
                </S.CircleTwo>
            </S.IconWrapper>
        </S.BoxIconAnimation>
    )
}

export default IconAnimation1sti
