import React from 'react';
import { useSwiper } from 'swiper/react';

import { ContainerArrow, ArrowButton, ArrowButtonLeft } from '../styles';

import ArrowL from '../../../assets/images/seta_esq.svg'
import ArrowR from '../../../assets/images/seta_dir.svg'


export default function SlideNextButton() {
  const swiper = useSwiper();
  return (
    <ContainerArrow>
      <ArrowButtonLeft
        onClick={() => swiper.slidePrev()}
      >
        <img
          src={ArrowL}
          alt="Seta para a esquerda"
        />


      </ArrowButtonLeft>
      <ArrowButton
        className="swiper-nav-btns"
        onClick={() => swiper.slideNext()}
      >
        <img
          src={ArrowR}
          alt="Seta para a direita"
        />
      </ArrowButton>
    </ContainerArrow>
  );
}