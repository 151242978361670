import styled from 'styled-components'

export const ContainerAttention = styled.section`
    width: 100%;
    background: #0b1617;
    margin-top: 6rem;
    @media (max-width: 768px) {
        height: 65vh;
        font-size: 0.9em;
        margin-top: 6.188rem;
    }
`
export const AttentionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    padding-inline: 70px;
    /* width: 100%; */
    height: 90vh;
    @media (max-width: 768px) {
        height: auto;
    }

    @media (max-width: 1024px) {
        padding-inline: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }
`

export const Box = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 6.563rem 0 7.313rem 0;
    width: 40.813rem;
    text-align: center;
    border: 2px solid #00c8db;
    box-shadow: inset 0 0 40px #00c8db;

    @media (max-width: 768px) {
        padding: 2.5rem 0 7rem 1.7rem;
        text-align: left;
        /* align-items: start; */
    }
    @media (max-width: 500px) {
        padding-right: 15px;
    }
    @media (max-width: 430px) {
        padding-bottom: 3.2rem;
    }
`
export const Title = styled.h2`
    font-size: 2.875rem;
    line-height: 55px;
    padding-bottom: 1.688rem;
    @media (max-width: 768px) {
        font-size: 2.813rem;
        line-height: 60px;
    }
    @media (max-width: 550px) {
        width: 100%;
    }
`

export const Description = styled.h3`
    width: 78%;
    font-size: 1.125rem;
    font-weight: 300;
    height: 4.938rem;
    padding-bottom: 6.5em;
    @media (max-width: 768px) {
        height: 4.563rem;
        padding-bottom: 7rem;
    }
    @media (max-width: 550px) {
        width: 100%;
    }
    @media (max-width: 430px) {
        padding-bottom: 11rem;
    }
`

export const Icon = styled.img`
    transition: 0.4s;
    width: 1.2rem;
    filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(325deg);

    @media (max-width: 500px) {
        width: 1.1rem;
    }
`

export const NewsLetter = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    color: #ff0070;
    width: 100%;
    height: auto;
    font-size: 1em;
    font-weight: 600;
    ::placeholder {
        color: #ff0070;
    }
    // ao clicar no input o placeholder some
    :focus {
        ::placeholder {
            color: transparent;
        }
    }
    @media (min-width: 500px) {
        min-width: 20rem;
    }
    @media (max-width: 768px) {
        font-size: 1.2rem;
        font-weight: 600;
    }
`

export const Label = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ff0070;
    width: 350px;
    height: 2rem;

    &:hover img {
        margin-right: -7px;
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`

export const Subtitle = styled.p`
    padding-top: 4rem;
    color: #00c8db;
    font-size: 0.875em;
`
