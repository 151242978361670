import { useState, useEffect } from 'react'
import api from '../modules/api'
export function useEmailMethods() {
    const [emails, setEmails] = useState([])
    let localStorage = null
    if (typeof window !== 'undefined') {
        localStorage = window.localStorage.getItem('userCredentials')
    }
    useEffect(() => {
        async function verifyEmail() {
            await api
                .get('/emails-node')
                .then((response) => {
                    setEmails(response?.data.emails)
                })
                .catch((error) => {
                    setEmails(error)
                })
        }
        verifyEmail()
    }, [localStorage])

    return {
        emails,
    }
}
