import styled, { keyframes } from 'styled-components'

const transitionTextCircle = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

export const BoxIconAnimation = styled.div`
    width: 100%;
    background: #fff;
    height: 250px;
    z-index: 13;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease-in-out;
    box-shadow: 10px 10px 50px 67px #fff;

    @media(max-width: 420px) {
        height: 40px;
        margin-bottom: 60px;
    }

    div {
        transition: all 0.3s ease-in-out;
    }
`

export const IconWrapper = styled.div`
    width: 30px;
    cursor: default;

    .one {
        transition: all 0.3s ease-in-out;
    }

    .two {
        transition: all 0.3s ease-in-out;
    }

    &:hover .two {
        margin-left: 15px;
        img {
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover .one {
        margin-left: -15px;

        svg {
            animation: ${transitionTextCircle} 0.3s ease-in-out forwards;
        }
    }
`

export const Circle = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    background-color: black;
    transition: all 0.3s ease-in-out;
    color: #fff;
`

export const CircleOne = styled(Circle)`
    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);

        margin-left: 0.95rem;
        z-index: 11;
    }
`

export const CircleTwo = styled(Circle)`
    img {
        width: 28px;
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }
`
