import styled from 'styled-components'
import { ContentCover } from '../ContentCover'
import { DateSharePrint } from '../DateSharePrint'

import { ArticleSelect } from '../ArticleSelect'

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    position: relative;

    display: flex;
    flex-direction: column;

    p {
        color: #000;
    }
`
export const CoverPage = styled(ContentCover)`
    @media (max-height: 600px) {
        .title {
            width: 100%;
            max-width: 600px;
            font-size: 70px;
            line-height: 60px;
        }
        @media (max-width: 540px) {
            .title h2 {
                width: 100%;
                max-width: 800px;
                font-size: 60px;
                line-height: 60px;
            }
        }
    }
`

export const Content = styled.div`
    z-index: 13;
    position: relative;
    padding-block: 150px;
    height: 100%;
`

export const ComponentArticleSelect = styled(ArticleSelect)`
    width: 100%;
    max-width: 690px;
`

export const ComponentDateSharePrint = styled(DateSharePrint)`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth && maxWidth};
    margin: 0 auto;
    padding-left: 2.438rem; 
    
    @media ${({ theme }) => theme.medias.lg} {
        margin-bottom: 30px;
        padding: 0;
    }
`

export const ContainerTexts = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 150px;

    & > p {
        font-family: 'CabinetGrotesk';
        font-size: 32px;
        font-weight: 500;
        width: 100%;
        max-width: 690px;
        z-index: 11;
        margin-bottom: 14px;
    }

    div {
        z-index: 13;
        margin-top: 100px;

        p {
            font-family: 'CabinetGrotesk';
            font-weight: 400;
            font-size: 20px; 
            text-align: center;
            margin-top: 25px;
            color: #0B1617;
        }
    }    

    .img-fill {
        width: 100%;
    }

    .img-large {
        width: 60%;
    }

    .img-media {
        width: 40%;
    }

    .img-small {
        width: 30%;
    }

    @media ${(props) => props.theme.medias.lg} {
        gap: 80px;

        div {
            margin-top: 20px;
        }

        .img-large {
            width: 80%;
        }

        .img-media {
            width: 70%;
        }

        .img-small {
            width: 40%;
        }

        & > p {
            padding-inline: 23px;
            font-size: 23px;
        }
    }
`

export const BoxImages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 243px;
    z-index: 11;
    p {
        font-size: 1.5rem;
        color: #000;
        padding: 25px 0;
        z-index: 11;
    }

    @media (max-width: 600px) {
        padding-top: 95px;
    }

    img:first-child {
        width: 408px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 50%;
        }
    }

    img:nth-child(2) {
        padding-top: 342px;
        width: 600px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 100%;
            padding-top: 180px;
        }
    }
    img:nth-child(3) {
        padding-bottom: 325px;
        width: 600px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 100%;
            padding-bottom: 149px;
        }
    }
    img:nth-child(4) {
        width: 730px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 50%;
        }
    }

    .mask-group {
        padding-top: 446px;
        width: 402px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 100%;
            padding-top: 155px;
        }
    }
    .lab {
        padding-top: 373px;
        width: 512px;
        object-fit: cover;
        z-index: 11;

        @media (max-width: 600px) {
            width: 80%;
            padding-top: 127px;
        }
    }
`

export const BoxTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 66px 0 38px 0;
`

export const Title = styled.h3`
    font-family: 'RocGroteskCompressed';

    font-weight: bold;
    font-size: 100px;
    letter-spacing: 0px;
    line-height: 80px;
    text-transform: uppercase;
    opacity: 1;
    color: #000;
    z-index: 11;
    position: relative;
    @media (max-width: 700px) {
        width: 100%;
        max-width: 320px;
        font-size: 52px;
        line-height: 50px;
        letter-spacing: 0px;
    }
`

export const ContainerCredit = styled.div`
    display: flex;
    justify-content: center;
    z-index: 13;

`

export const BoxCredit = styled.section`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 800px;
    z-index: 11;
    padding-left: 2.438rem; 

    h5 {
        font-family: 'GoshaSans-Bold';
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #171717;
    }

    p {
        font-family: 'CabinetGrotesk';
        font-weight: 400;
        font-size: 20px;
    }

    p span {
        font-weight: 700;
    }
    @media (max-width: 600px) {
        padding: 0 30px;
        h5 {
            font-family: 'GoshaSans-Bold';
            font-size: 16px;
            letter-spacing: 0.8px;
            color: #171717;
        }

        p {
            font-size: 18px;
            max-width: 480px;
        }
    }
`