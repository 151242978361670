import styled from "styled-components"

export const Wrapper = styled.div`
  padding-inline: 70px;
  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 690px) {
    padding-inline: 23px;
  }
`
