import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../utils/windowSizeScreen'

// i18
import { i18n } from '../../translate/i18n'

// Styles
import * as S from './styles'


export function ContentCover({
    backgroundCover,
    backgroundMobile,
    title,
    titleEn,
    buttonOne,
    buttonTwo,
    className,
    background,
    imageCenter,
}) {
    const { width } = useWindowDimensions()
    const backgroundColor = background?.replace(/["]/g, '')
    const [onLoad, setOnLoad] = useState(false)
    // Verificar se está em portugues
    const isLanguangePt = i18n.language === 'pt-BR'
    useEffect(() => {
        setTimeout(() => {
            setOnLoad(true)
        }, 350)
    }, [])
    return (
        <S.ContainerCoverArticle
            className={className}
            backgroundCover={backgroundCover}
            backgroundMobile={backgroundMobile}
            background={backgroundColor}
            width={width}
        >
            {imageCenter && (
                <S.BoxImageCenter>
                <img src={imageCenter} alt="" />
                </S.BoxImageCenter>
            )}
            <S.ContentCover>
                <S.BoxInfoArticle onLoaded={onLoad}>
                    <div>
                        <h2 className="title">
                            {isLanguangePt ? title : titleEn}
                        </h2>
                    </div>
                    {/* <S.BoxTypeArticle>
                        <button>{buttonOne}</button>
                        <button>{buttonTwo}</button>
                    </S.BoxTypeArticle> */}
                </S.BoxInfoArticle>
            </S.ContentCover>
            <S.Shadow />
        </S.ContainerCoverArticle>
    )
}
