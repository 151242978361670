import styled from "styled-components";

export const ArticleSelect = styled.div`
    position: fixed;
    top: 50%;
    transform: translate(-0%,-50%);
    left: 0;
    transition: all 0.2s ease-in-out;
    opacity: ${(props) => (props.visible ? '1' : '0')};
    color: #000;
    z-index: 12;
    padding-inline: 70px;

    div {
        h3 {
            max-width: 250px;
            line-height: 120%;

            font-family: 'RocGroteskCompressed';
            font-weight: bold;
            font-size: 3.2vw;
            letter-spacing: 2px;

            text-transform: uppercase;
        }

        a {
            display: flex;
            align-items: center;
            gap: 16px;

            font-size: 14px;
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;

            color: #742698;
            & img {
                width: auto;
                opacity: 0.4;
            }
        }
    }

    @media(max-width: 1280px) {
        div {
            h3 {
                max-width: 220px;
                font-size: 5vw;
            }

            a {
                gap: 8px;
            }
        }
    }

    @media (max-width: 1100px) {
        position: initial;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.6rem;
        transform: translate(0%, 0%);

        div {
            h3 {
                max-width: 600px;
                font-family: 'RocGroteskCompressed';
                font-size: 75px;
                font-weight: bold;
                color: #000;
                line-height: 65px;
                padding: 1rem 0 1rem 0;
                z-index: 11;
                text-align: center;
            }

            a {
                display: none;
            }
        }
    }


    @media ${({ theme }) => theme.medias.lg} {
        div {
            h3 {
                width: 100%;
                max-width: 250px;
            }
        }
    }
`