import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  if(typeof window !== 'undefined') {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
  }
  return { width: null, height: null };
};

export default function useWindowDimensions() {
  const [windowDimesions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleSize = () => {
      setWindowDimensions(getWindowDimensions);
    };

    window.addEventListener('resize', handleSize);

    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return windowDimesions;
}