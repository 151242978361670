import React, { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y } from 'swiper'
import useWindowDimensions from '../../utils/windowSizeScreen'

// i18
import { i18n } from "../../translate/i18n"

// Styles
import * as S from './styles'

// Components
import { ArticleCarrosel } from '../ArticleCarrosel'
import SlideNextButton from './ButtonNavigation'


export function MenuDynamic() {
    const { width } = useWindowDimensions()
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [isActive, setIsActive] = useState('figuring-out')

    const data = useStaticQuery(graphql`
        query {
            firsti {
                coverMagazines {
                    titleMagazine
                }
                menuDynamics {
                    anchor
                    id
                    title
                    titleEn
                    description
                    descriptionEn
                    isMenuArticles
                    typePage
                  }
                formArticleFiguringOuts {
                    id
                    titleArticle
                    titleArticleEn
                    buttons
                    typePage
                    backgroundCoverGradient
                    backgroundMobile {
                        url
                    }
                    backgroundCover {
                        url
                    }
                }
                formArticleZooms {
                    id
                    titleArticle
                    titleArticleEn
                    buttons
                    typePage
                    backgroundCoverGradient
                    backgroundMobile {
                        url
                    }
                    backgroundCover {
                        url
                    }
                }
                formArticleThinkingBeyonds {
                    id
                    titleArticle
                    titleArticleEn
                    buttons
                    typePage
                    backgroundMobile {
                        url
                    }
                    backgroundCover {
                        url
                    }
                }
                formArticleTechnicolors {
                    id
                    titleArticle
                    titleArticleEn
                    buttons
                    typePage
                    backgroundCover {
                        url
                    }
                }
                menuSection {
                    label
                    link
                }
            }
        }
    `)
    const firguringOuts = data.firsti.formArticleFiguringOuts
    const thinkingBeyonds = data.firsti.formArticleThinkingBeyonds
    const zooms = data.firsti.formArticleZooms
    const articleTechnicolors = data.firsti.formArticleTechnicolors
    const articles = firguringOuts.concat(
        zooms,
        thinkingBeyonds,
        articleTechnicolors
    )
    const menuList = data.firsti.menuDynamics

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    function handleCloseMenu() {
        setIsOpenMenu(false)
    }

    function scrollToElement(id) {
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            })
        }
    }
    function getCoord(elem) {
        const overflow = elem?.getBoundingClientRect()
        return {
            top: overflow?.top,
            bottom: overflow?.bottom,
            height: overflow?.height,
            y: overflow?.y,
        }
    }

    function isScrolledIntoView(ev) {
        const coordOverflow = getCoord(ev.target)
        const list = menuList.map(item => item.anchor)
        let isVisible = isActive
        list.forEach((i) => {
            const element = document.getElementById(i)
            const coordElement = getCoord(element)

            if (
                coordElement.top >= coordOverflow.top &&
                coordElement.bottom <= window.innerHeight
            ) {
                isVisible = i
            }
        })

        setIsActive(isVisible)
    }

    function mouseEnter() {
        setTimeout(() => {
            setIsOpenMenu(true)
        }, '500')
    }
    function mouseLeave() {
        setTimeout(() => {
            setIsOpenMenu(false)
        }, '500')
    }

    return (
        <S.ContainerMenuDynamic onMouseLeave={mouseLeave}>
            <S.ContentMenu
                className="content"
                isOpenMenu={isOpenMenu}
                onMouseEnter={mouseEnter}
            >
                <S.ContainerMenuArtitles >
                    <S.MenuHamburguer
                        className="menu-hamburguer"
                        onClick={handleCloseMenu}
                        isOpenMenu={isOpenMenu}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                    </S.MenuHamburguer>

                    <S.ListMenu className="list-menu">
                        {menuList.map(item => {
                            const typePage = item.typePage
                            const hasAticleInCarrosel = articles.find(item => item.typePage === typePage)

                            if(hasAticleInCarrosel && item.isMenuArticles) {
                                return (
                                    <S.ItemMenuArtitles key={item.anchor}>
                                        <S.ButtonMenu
                                            onClick={() =>
                                                scrollToElement(item.anchor)
                                            }
                                            isActive={item.anchor === isActive}
                                        >
                                            {isLanguangePt ? item.title : item.titleEn}
                                        </S.ButtonMenu>
                                    </S.ItemMenuArtitles>
                                )
                            }
                            return false
                            
                        })}
                    </S.ListMenu>
                </S.ContainerMenuArtitles>
                <hr />
                <S.ContainerSections className="container-sections">
                    <S.ListMenu className="list-menu">
                        {menuList.map((menu) => {
                            if(menu.isMenuArticles === false) {
                                return (
                                    <S.ItemMenuBase
                                        key={menu.link}
                                    >
                                        <AnchorLink offset="100" href={`#${menu.anchor}`}>
                                            {isLanguangePt ? menu.title : menu.titleEn}
                                        </AnchorLink>
                                    </S.ItemMenuBase>
                                )
                            }
                            return false
                        })}
                    </S.ListMenu>
                </S.ContainerSections>
            </S.ContentMenu>

            <S.ContainerSlidersArtitles
                id="container-carousel"
                onScroll={isScrolledIntoView}
                onMouseEnter={mouseEnter}
            >
                {menuList.map(item => {
                    const typePage = item.typePage
                    const hasAticleInCarrosel = articles.find(item => item.typePage === typePage) 

                    if(item.isMenuArticles && hasAticleInCarrosel) {
                        return (
                            <S.ContentSectionArticle id={item.anchor}>
                                <S.Heading >
                                    <h2>{isLanguangePt ? item.title : item.titleEn}</h2>
                                    <p>
                                        {isLanguangePt ? item.description : item.descriptionEn}
                                    </p>
                                </S.Heading>
                                <S.ContainerSlide
                                    id="slide"
                                    width={width}
                                    
                                >
                                    <Swiper
                                        spaceBetween={16}
                                        slidesPerView={'auto'}
                                        modules={[Navigation, Pagination, A11y]}
                                        navigation
                                        draggable
                                    >
                                        {width >= 420 && <SlideNextButton />}
                                        {articles.map((item) => {
                                            if(item.typePage === typePage) {
                                                return (
                                                    <SwiperSlide>
                                                        <Link
                                                            to={
                                                                '/article/' +
                                                                item.typePage +
                                                                '/' +
                                                                item.id
                                                            }
                                                        >
                                                            <ArticleCarrosel
                                                                backgroundCoverGradient={
                                                                    item.backgroundCoverGradient
                                                                }
                                                                backgroundCover={
                                                                    item.backgroundCover
                                                                }
                                                                backgroundMobile={
                                                                    item.backgroundMobile
                                                                }
                                                                titleArticle={item.titleArticle}
                                                                titleArticleEn={item.titleArticleEn}
                                                                buttons={item.buttons}
                                                                isOpenMenu={isOpenMenu}
                                                            />
                                                        </Link>
                                                    </SwiperSlide>
                                                )
                                            }

                                            return false
                                        })}
                                    </Swiper>
                                </S.ContainerSlide>
                            </S.ContentSectionArticle>
                        )
                    }
                    return false
                })}
            </S.ContainerSlidersArtitles>
        </S.ContainerMenuDynamic>
    )
}
