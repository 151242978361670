import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Styles
import * as S from './styles'

//components
import { ContentServive } from '../ContentService'

export default function SocialStrategy() {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                contentCard {
                    title
                    titlePt
                    subtitle
                    subtitlePt
                    titleContactUs
                    titleContactUsPt
                    textButtonContactUs
                    textButtonContactUsPt
                    texts {
                        html
                    }
                    textsPt {
                        html
                    }

                    imagePt {
                        url
                    }
                    imageEn {
                        url
                    }
                    imageMobPt {
                        url
                    }
                    imageMobEn {
                        url
                    }
                    iconPlus {
                        url
                    }
                }
            }
        }
    `)

    const deepDigital = data.firsti.contentCard[0]

    return (
        <S.DBContainer>
          <ContentServive
              dataService={deepDigital}
          />
        </S.DBContainer>
    )
}
