import React, { useState } from 'react'

// Styles
import * as S from './styled'

// Hooks
import useWindowDimensions from '../../utils/windowSizeScreen'
import { useSocialShare } from '../../Hooks/useSocialShare'

// i18
import { i18n } from "../../translate/i18n"

// Icons
import ShareIcon from '../../assets/icons/share_icon.svg'

const ShareArticle = ({ pageContext }) => {
    const { width } = useWindowDimensions()
    const { medias } = useSocialShare(pageContext?.titleArticle)
    const [shareSocialContainer, setShareSocialContainer] = useState(false)
    let url = null

    if (typeof window !== 'undefined') {
        url = window.location.href
    }

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    const handleSharing = async () => {
        const data = {
            url: url,
            title: pageContext?.titleArticle,
            text: 'Olha o artigo que eu encontrei',
        }
        if (width < 420) {
            if (navigator.share) {
                try {
                    await navigator
                        .share(data)
                        .then(() =>
                            console.log(
                                'Hooray! Your content was shared to tha world'
                            )
                        )
                } catch (error) {
                    console.log(
                        `Oops! I couldn't share to the world because: ${error}`
                    )
                }
            } else {
                // fallback code
                console.log(
                    'Web share is currently not supported on this browser. Please provide a callback'
                )
            }
        } else {
            setShareSocialContainer(!shareSocialContainer)
        }
    }

    return (
        <S.BoxShare>
            <S.SocialArea>
                <S.SocialShareButton onClick={handleSharing}>
                    <p>{isLanguangePt ? 'Compartilhar artigo' : 'share article'}</p> <img src={ShareIcon} alt="Icone de compartilhar" />
                </S.SocialShareButton>
                {shareSocialContainer && medias && (
                    <S.SocialContainer>
                        {medias.map((item) => (
                            <S.LinkTo
                                href={item.path}
                                target="_blank"
                                rel="nofollow noopener"
                            >
                                <img src={item.icon} alt={item.name} />
                            </S.LinkTo>
                        ))}
                    </S.SocialContainer>
                )}
            </S.SocialArea>
        </S.BoxShare>
    )
}

export default ShareArticle
