import React from 'react'

// Styles
import * as S from './styles'

// Components
import PrintButtonArticle from '../PrintArticle'
import ShareArticle from '../ShareArticle'

// i18
import { i18n } from "../../translate/i18n"

export function DateSharePrint({ date, dateEn , hasDate = true, className }) {

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <S.Container className={className}>
            {hasDate && <S.ArticleDate>{isLanguangePt ? date : dateEn}</S.ArticleDate>}
            <S.PrintShareButtons hasDate={hasDate}>
                <ShareArticle />
                <PrintButtonArticle />
            </S.PrintShareButtons>
        </S.Container>
    )
}