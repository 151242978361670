import React from "react"
import Layout from "../components/Layout"

import SocialStrategy from "../components/ContentCards/SocialStrategy.js"

const ContentCardsPage = () => {
  return (
    <Layout seo="1STi | Deep Digital">
      <SocialStrategy />
    </Layout>
  )
}

export default ContentCardsPage

