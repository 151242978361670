import React from "react"

import Layout from "../components/Layout"
import Content from "../components/ContactContent"

const Contact = () => (
  <Layout seoTitle="1STi | Contact">
    <Content />
  </Layout>
)

export default Contact
