import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;

    height: 150px;

    @media ${({ theme }) => theme.medias.lg} {
        height: 90px;
        flex-wrap: wrap;
    }
`

export const ArticleDate = styled.p`
    font-family: 'roc-grotesk';
    font-size: 19px;
    width: 100%;

    @media ${({ theme }) => theme.medias.lg} {
        font-size: 15px;
        text-align: center;
    }
`

export const PrintShareButtons = styled.div`
    display: flex;
    justify-content: ${({ hasDate }) => !hasDate ? 'flex-start' : 'center' };
    gap: 12px;

    width: 100%;

    @media ${({ theme }) => theme.medias.lg} {
        justify-content: ${({ hasDate }) => !hasDate && 'center'};
    }
`