import React from 'react'

// Styles
import * as S from './styles'

// i18
import { i18n } from "../../translate/i18n"

export function ArticleCarrosel({
    backgroundCoverGradient,
    backgroundCover,
    backgroundMobile,
    titleArticle,
    titleArticleEn,
    buttons,
    isOpenMenu,
}) {

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <S.Container>
            <S.CoverArtitle
                isOpenMenu={isOpenMenu}
                background={backgroundCoverGradient}
                imageCover={backgroundCover?.url}
                imageCoverMobile={backgroundMobile?.url}
            >
                <h3>{isLanguangePt ? titleArticle : titleArticleEn}</h3>
            </S.CoverArtitle>
            {/* <S.BoxTypeArticle>
                {buttons && buttons.map((type) => <span>{type.label}</span>)}
            </S.BoxTypeArticle> */}
        </S.Container>
    )
}
