import React from 'react'
import * as S from './styles'

import { Swiper, SwiperSlide } from 'swiper/react'

import SlideNextButton from './ButtonNavigation'

//i18n
import { i18n } from '../../translate/i18n'

export default function Pioneering({ data }) {
    i18n.addResourceBundle('en', 'translations', data)
    i18n.addResourceBundle('pt', 'translations', data)

    return (
        <S.Container>
            <S.PioneeringSection>
                <S.Left>
                    <S.Title>
                        {i18n.t(i18n.language === 'pt-BR' ? 'titlePt' : 'title')}
                    </S.Title>
                    <S.Text>
                        {i18n.t(i18n.language === 'pt-BR' ? 'textPt' : 'text')}
                    </S.Text>
                    <S.AlertMsg>
                        {i18n.language === 'pt-BR' ? data.alertPt : data.alert}
                    </S.AlertMsg>
                    <a href="https://deeptechamplified.com" target="_blank">
                        <S.ButtonDesktop>
                            {i18n.t(
                                i18n.language === 'pt-BR'
                                    ? 'buttonTextPt'
                                    : 'buttonText'
                            )}
                        </S.ButtonDesktop>
                    </a>
                </S.Left>

                <S.Right>
                    <S.BoxImageBook isResponsive>
                        <S.ImageBook src={data.bookImg.url} alt={data.altImg} />
                    </S.BoxImageBook>
                    <S.BoxImageBook>
                        <S.ImageBook src={data.bookImg.url} alt={data.altImg} />
                    </S.BoxImageBook>

                    <S.Slider>
                        <Swiper spaceBetween={15} slidesPerView={1} loop>
                            {i18n.language === 'pt-BR'
                                ? data.slider.slidersPt.map((item) => (
                                      <SwiperSlide>
                                          <S.SlideOne>
                                              <section>
                                                  <S.SliderAuthor>
                                                      {item.author}
                                                  </S.SliderAuthor>
                                                  <S.SliderAuthorPosition>
                                                      {item.position}
                                                  </S.SliderAuthorPosition>
                                                  <S.SliderAuthorText>
                                                      {item.text}
                                                  </S.SliderAuthorText>
                                              </section>
                                          </S.SlideOne>
                                      </SwiperSlide>
                                  ))
                                : data.slider.sliders.map((item) => (
                                      <SwiperSlide>
                                          <S.SlideOne>
                                              <section>
                                                  <S.SliderAuthor>
                                                      {item.author}
                                                  </S.SliderAuthor>
                                                  <S.SliderAuthorPosition>
                                                      {item.position}
                                                  </S.SliderAuthorPosition>
                                                  <S.SliderAuthorText>
                                                      {item.text}
                                                  </S.SliderAuthorText>
                                              </section>
                                          </S.SlideOne>
                                      </SwiperSlide>
                                  ))}
                            <SlideNextButton />
                        </Swiper>
                    </S.Slider>
                </S.Right>
            </S.PioneeringSection>
        </S.Container>
    )
}
