import React from 'react'
import * as S from './styled'

import CardServices from '../../CardServices'

// i18n
import { i18n } from '../../../translate/i18n'

export default function Services({ data }) {
    i18n.addResourceBundle('en', 'translations', data)
    i18n.addResourceBundle('pt', 'translations', data)

    return (
        <S.ContainerServices>
            <S.ContentContainer>
                <S.BoxText>
                    <S.Title>
                        {i18n.t(i18n.language === 'pt-BR' ? 'titlePt' : 'title')}
                    </S.Title>
                    <S.Description>
                        {i18n.t(
                            i18n.language === 'pt-BR' ? 'paragraphPt' : 'paragraph'
                        )}
                    </S.Description>
                </S.BoxText>
                <S.CardsContainer>
                    {i18n.language === 'pt-BR'
                        ? data.cards.cardPt.map((item, i) => (
                              <CardServices
                                  key={i}
                                  title={item.title}
                                  subscription={item.text}
                                  link={item.link}
                              />
                          ))
                        : data.cards.card.map((item, i) => (
                              <CardServices
                                  key={i}
                                  titleEn={item.title}
                                  subscription={item.text}
                                  link={item.link}
                              />
                          ))}
                </S.CardsContainer>
            </S.ContentContainer>
        </S.ContainerServices>
    )
}
