import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// styles
import * as S from "./styled.js"

// libs
import "react-toastify/dist/ReactToastify.css"

// components
import SubscribeButton from "../SubscribeButton"
import Header from "../Header"
import ToastAnimated, { showToast } from "../Toast"

// i18n
import { i18n } from "../../translate/i18n"

// assets
import GlobeIcon from "../../assets/images/globe_icon.svg"
import Arrow from "../../assets/images/arrow-icon.png"
import BookMob from "../../assets/images/livro-contato.svg"
import ArrowRight from "../../assets/images/arrow-up-right-blue.svg"
import api from "../../modules/api.js"
import Loading from "../Loading/index.js"

const Content = () => {
  const [open, setOpen] = React.useState(false)
  const [textCopy, setTextCopy] = React.useState("ola@1sti.com.br")
  const [form, setForm] = React.useState({
    email: "",
  })
  const data = useStaticQuery(graphql`
    query {
      firsti {
        contentContacts {
          titleContact
          titleContactPt
          descriptionContact
          descriptionContactPt
          descriptionContactUs
          descriptionContactUsPt
          titleBook
          titleBookPt
          paragraphBook
          paragraphBookPt
          deepLink
          titleNewsletter
          titleNewsletterPt
          paragraphNewsletter
          paragraphNewsletterPt
          titleCareers
          titleCareersPt
          paragraphCareers
          paragraphCareersPt
          imageBook {
            url
          }
          linkCarrers
          textJoinUs
          textJoinUsPt
        }
      }
    }
  `)

  const {
    titleContact,
    titleContactPt,
    descriptionContact,
    descriptionContactPt,
    descriptionContactUs,
    descriptionContactUsPt,
    titleBook,
    titleBookPt,
    paragraphBook,
    paragraphBookPt,
    deepLink,
    titleNewsletter,
    titleNewsletterPt,
    paragraphNewsletter,
    paragraphNewsletterPt,
    titleCareers,
    titleCareersPt,
    paragraphCareers,
    paragraphCareersPt,
    imageBook,
    linkCarrers,
    textJoinUs,
    textJoinUsPt,
  } = data.firsti.contentContacts[0]

  i18n.addResourceBundle("en", "translations", data)
  i18n.addResourceBundle("pt", "translations", data)

  const loading = () => {
    setOpen(true)
  }

  const handleToggle = () => {
    setOpen(!open)

    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  const handleChange = e => {
    const { value, name } = e.target

    const updateForm = {
      ...form,
      [name]: value,
    }

    setForm(updateForm)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setOpen(true)
    api
      .post("/register-email", {
        email: form.email,
      })
      .then(response => {
        if (response.status === 200) {
          setForm({
            email: "",
          })
        }
        if (response.data.message === "Email registered successfully") {
          i18n.language === "en-US"
            ? showToast({
                message: response?.data?.message,
                type: "success",
              })
            : showToast({
                message: "Email registrado com sucesso!",
                type: "success",
              })
        }
      })
      .catch(error => {
        setOpen(false)
        if (error?.response?.data?.message === "Email already registered") {
          i18n.language === "en-US"
            ? showToast({
                message: error?.response?.data?.message,
                type: "error",
              })
            : showToast({
                message: "E-mail já registrado",
                type: "error",
              })
        }
        if (error?.response?.data?.message === "Email is required") {
          i18n.language === "en-US"
            ? showToast({
                message: error?.response?.data?.message,
                type: "error",
              })
            : showToast({
                message: "Email obrigatório",
                type: "error",
              })
        }
      })
  }

  return (
    <S.Container>
      <S.Wrapper onSubmit={handleSubmit}>
        <S.BoxTitleBook>
          <Header
            positionText="3rem"
            title={i18n.language === "en-US" ? titleContact : titleContactPt}
          />
        </S.BoxTitleBook>
        <S.Content>
          <S.BoxDescriptionContact>
            <S.UpText>
              <p>
                {i18n.language === "en-US"
                  ? descriptionContact
                  : descriptionContactPt}
              </p>
              <p>
                {i18n.language === "en-US"
                  ? descriptionContactUs
                  : descriptionContactUsPt}

                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(textCopy)
                    i18n.language === "en-US"
                      ? showToast({
                          message: "Email copied to clipboard",
                          type: "success",
                        })
                      : showToast({
                          message: "Email copiado para a área de transferência",
                          type: "success",
                        })
                  }}
                >
                  <input
                    type="text"
                    value={textCopy}
                    onChange={e => setTextCopy(e.target.value)}
                    readOnly
                  />
                </button>
              </p>
            </S.UpText>
          </S.BoxDescriptionContact>
          <S.BoxBook>
            <S.LineBox>
              <S.Boxes>
                <h4>{i18n.language === "en-US" ? titleBook : titleBookPt}</h4>
                <p>
                  {i18n.language === "en-US" ? paragraphBook : paragraphBookPt}
                </p>
                <S.BookLink>
                  <img src={GlobeIcon} alt="" />
                  <a href={deepLink} target="_blank" rel="noreferrer">
                    deeptechamplified.com
                  </a>
                  <span>
                    <img src={ArrowRight} alt="right arrow" />
                  </span>
                </S.BookLink>
                <S.Image
                  src={imageBook.url}
                  alt="Book cover - 'Deep Tech and the amplified organization, by Simon Robinson, Igor Couto and Maria Moraes Robinson'"
                />
                <S.Image
                  src={BookMob}
                  alt="Book cover - 'Deep Tech and the amplified organization, by Simon Robinson, Igor Couto and Maria Moraes Robinson'"
                  isResponsive
                />
              </S.Boxes>
              <S.Boxes>
                <h4>
                  {i18n.language === "en-US" ? titleNewsletter : titleNewsletterPt}
                </h4>
                <p>
                  {i18n.language === "en-US"
                    ? paragraphNewsletter
                    : paragraphNewsletterPt}
                </p>
                <S.Label>
                  <S.NewsLetter
                    type="email"
                    name="email"
                    value={form.email}
                    autoComplete="off"
                    onChange={handleChange}
                    placeholder={
                      i18n.language === "en-US"
                        ? "Enter your email"
                        : "Digite seu email"
                    }
                  />
                  <SubscribeButton onClick={handleToggle} type="submit">
                    <S.Icon src={Arrow} alt="Arrow icon" />
                  </SubscribeButton>
                  {open && <Loading onChange={loading} />}
                </S.Label>
              </S.Boxes>
              <S.Boxes>
                <h4>
                  {i18n.language === "en-US" ? titleCareers : titleCareersPt}
                </h4>
                <p>
                  {i18n.language === "en-US"
                    ? paragraphCareers
                    : paragraphCareersPt}
                  <a href={linkCarrers} target="_BLANK">
                    {i18n.language === "en-US" ? textJoinUs : textJoinUsPt}
                  </a>
                </p>
              </S.Boxes>
            </S.LineBox>
          </S.BoxBook>
        </S.Content>
      </S.Wrapper>
      <ToastAnimated />
    </S.Container>
  )
}

export default Content
