import styled, { keyframes, css } from 'styled-components'

const onShow = keyframes`
  from {
    opacity: 0;
    z-index: -1;
  }

  to {
    display: flex;
    opacity: 1;
    z-index: 99;

}`

const onClose = keyframes`
  from {
    opacity: 1;
    z-index: 99;

  }
  
  to {
    opacity: 0;
    z-index: -1;

}`

const openAnimation = css`
    animation: 0.3s linear ${onShow} forwards;
`
const closeAnimation = css`
    animation: 0.3s linear ${onClose} forwards;
`

export const ContainerOutside = styled.div`
    width: 100%;
    height: 100%;
    background-color: #ffffffbf;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    ${({ isOpenModal }) =>
        isOpenModal !== null && (isOpenModal ? openAnimation : closeAnimation)}
`
export const Container = styled.div`
    width: 100%;
    max-width: 585px;

    padding: 72px;
    background-color: red;
    background-color: #0038fa;
    box-shadow: inset 0px 0px 30px #00c8db;
    border-radius: 10px;
    opacity: 1;
    padding-bottom: 3.25rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    @media (max-width: 420px) {
        width: 95%;
        padding: 45px 40px;
    }
`
export const ContainerCloseButton = styled.div`
    position: absolute;
    right: 38px;
    top: 35px;
`

export const CloseButton = styled.button`
    background: transparent;
    width: 25px;
    height: 25px;
    border: none;
    color: #ffffff;
    cursor: pointer;
`
export const IconCloseButton = styled.img`
    width: 25px;
    height: 25px;
    color: #fff;
`

export const Heading = styled.div`
    width: 100%;
`

export const Title = styled.h3`
    width: 100%;
    font-size: 60px;
    text-align: left;
    font-family: 'roc-grotesk';
    font-weight: 300;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    height: 100%;
    line-height: 70px;

    @media (max-width: 420px) {
        font-size: 37px;
        line-height: 40px;
        height: 50%;
    }
`
export const Subtitle = styled.p`
    max-width: 350px;
    text-align: left;
    font-family: 'CabinetGrotesk';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding-bottom: 10px;

    @media (max-width: 420px) {
        max-width: 180px;
        font-size: 14px;
    }
`
export const ContainerCredentials = styled.form`
    width: 100%;
`

export const TitleInput = styled.p`
    text-align: left;
    font-size: 22px;
    font-family: 'CabinetGrotesk';
    font-weight: 500;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 6px;

    @media (max-width: 420px) {
        font-size: 16px;
    }
`
export const Input = styled.input`
    background-color: transparent;
    border-bottom: 1px solid #ffffff;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 30px;
    font-size: 16px;

    font-weight: 300;
    color: #ffffff;
    width: 100%;

    outline: none;

    &::placeholder {
        letter-spacing: 0px;
        color: #ffffff;
        font-family: 'CabinetGrotesk';
        font-size: 18px;
        font-weight: 300;

        @media (max-width: 420px) {
            font-size: 15px;
        }
    }
`

export const ButtonGo = styled.button`
    width: 100%;
    height: 100%;
    border: none;

    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0;
    padding-top: 2.25rem;

    cursor: pointer;
    background-color: transparent;

    span {
        font-family: 'roc-grotesk-extrawide-bold';
        font-weight: 600;
        font-size: 60px;
        color: #fff;

        @media (max-width: 420px) {
            font-size: 30px;
        }
    }

    @media (max-width: 420px) {
        height: 45px;
    }
`
export const GoIcon = styled.img``

export const IconArrow = styled.img``

export const LabelMessage = styled.p`
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'CabinetGrotesk';
    font-weight: 500;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin: 0;
`
