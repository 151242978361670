import WhatsAppIcon from '../assets/icons/whatsapp_icon.svg'
import LinkedInIcon from '../assets/icons/linkedin_icon.svg'
import TwitterIcon from '../assets/icons/twitter_icon.svg'
import FacebookIcon from '../assets/icons/facebook_icon.svg'

export function useSocialShare(title) {
    let url = null
    if (typeof window !== 'undefined') {
        url = window.location.href
    }
    const medias = [
        {
            name: 'WhatsApp',
            icon: WhatsAppIcon,
            path: 'whatsapp://send?text=' + url,
        },
        {
            name: 'LinkedIn',
            icon: LinkedInIcon,
            path:
                'https://www.linkedin.com/shareArticle?url=' +
                url +
                '&title=' +
                title,
        },
        {
            name: 'Twitter',
            icon: TwitterIcon,
            path:
                'https://twitter.com/intent/tweet?url=' +
                url +
                '&text=' +
                title,
        },
        {
            name: 'Facebook',
            icon: FacebookIcon,
            path: 'https://www.facebook.com/sharer/sharer.php?u=' + url,
        },
    ]
    return {
        medias,
    }
}
