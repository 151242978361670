exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-binocular-articles-index-js": () => import("./../../../src/components/BinocularArticles/index.js" /* webpackChunkName: "component---src-components-binocular-articles-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backbones-digitais-js": () => import("./../../../src/pages/backbones-digitais.js" /* webpackChunkName: "component---src-pages-backbones-digitais-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cover-page-js": () => import("./../../../src/pages/cover-page.js" /* webpackChunkName: "component---src-pages-cover-page-js" */),
  "component---src-pages-deep-analytics-js": () => import("./../../../src/pages/deep-analytics.js" /* webpackChunkName: "component---src-pages-deep-analytics-js" */),
  "component---src-pages-deep-tech-innovation-js": () => import("./../../../src/pages/deep-tech-innovation.js" /* webpackChunkName: "component---src-pages-deep-tech-innovation-js" */),
  "component---src-pages-digital-platforms-js": () => import("./../../../src/pages/digital-platforms.js" /* webpackChunkName: "component---src-pages-digital-platforms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-social-strategy-js": () => import("./../../../src/pages/social-strategy.js" /* webpackChunkName: "component---src-pages-social-strategy-js" */)
}

