import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const SwipperContainer = styled(Swiper)`
    display: flex;
    border: 2px solid #000;
    width: 100%;
    background-color: lightpink;
`

export const ContainerSlide = styled.div`
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    height: 500px;
    overflow: auto;
    z-index: 15;

    margin-top: 20px;
    margin-bottom: 20px;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    img {
        object-fit: cover;
        z-index: 15;
    }
`
