import React from 'react'

// Styles
import * as S from './styled'

// Icons
import PrintIcon from '../../assets/icons/printer_icon.svg'

// i18
import { i18n } from "../../translate/i18n"

const PrintButtonArticle = () => {
    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    const handlePrint = () => {
        window.print()
    }

    return (
        <S.ArticlePrintButton onClick={handlePrint}>
            <p>{isLanguangePt ? 'Imprimir' : 'print out'}</p> <img src={PrintIcon} alt="Icone impressora" />
        </S.ArticlePrintButton>
    )
}

export default PrintButtonArticle
