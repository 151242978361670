import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-inline: 70px;

  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 690px) {
    padding-inline: 23px;
  }
`

export const Wrapper = styled.form`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 4rem 0;
  @media (max-width: 768px) {
    padding: 4rem 0rem;
  }
`

export const BoxTitleBook = styled.div``

export const Content = styled.div`
  padding-left: 6%;
  @media (max-width: 1200px) {
    padding-left: 0;
  }
  @media (max-width: 1440px) {
    margin-left: 0;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const BoxDescriptionContact = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 2rem;
    padding-left: 1rem;
    margin-left: 0;
  }
`

export const UpText = styled.div`
  max-width: 770px;
  width: 100%;

  font-size: 1.75rem;
  font-weight: 300;
  line-height: 36px;

  p {
    padding-bottom: 1rem;
    width: 100%;
    max-width: 768px;
  }

  button {
    background: transparent;
    border: none;
    @media (max-width: 507px) {
      margin-left: -10px;
    }
  }

  input:hover {
    text-decoration: underline;
  }

  input {
    width: 200px;
    background: transparent;
    border: none;
    color: #00c8db;
    font-size: 1.75rem;
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    margin-left: -20px;
    font-size: 1.3em;
    line-height: 1.5;
  }
`
export const BoxCopy = styled.div`
  display: flex;
`
export const BoxBook = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const LineBox = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 5rem 0 2rem;
  width: 100%;
  font-size: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 2rem 0 5rem;

    & > div {
      padding: 3rem 0;
      width: 100%;
    }
  }
`

export const Boxes = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.5;

  &:nth-child(2) {
    margin: 0 1rem;
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    letter-spacing: 0.125rem;
    font-weight: 500;
  }

  a {
    color: #fff;
    margin-left: 0.2rem;
  }
  @media (max-width: 1024px) {
    &:nth-child(2) {
      margin: 0;
    }
  }
`

export const BookLink = styled.div`
  margin: 1rem 0 2rem;
  display: flex;
  align-items: center;

  img {
    width: 1.5rem;
  }

  a {
    color: #38eeff;
    margin: 0 10px;
    font-weight: 300;
  }

  span {
    color: #38eeff;
    font-size: 1.3em;
    transition: 0.3s;
  }

  img,
  span {
    margin-top: 5px;
  }

  @media (min-width: 768px) {
    span {
      opacity: 0;
    }

    &:hover {
      a {
        font-weight: 600;
      }

      span {
        opacity: 1;
      }
    }
  }
`

export const NewsLetter = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  color: #ff0070;
  width: 100%;
  height: auto;
  font-size: 1em;
  font-weight: 600;
  ::placeholder {
    color: #ff0070;
  }
  // ao clicar no input o placeholder some
  :focus {
    ::placeholder {
      color: transparent;
    }
  }
  @media (min-width: 500px) {
    min-width: 20rem;
  }
`

export const Image = styled.img`
  display: ${props => (props.isResponsive ? "none" : "flex")};
  box-shadow: 0 0 25px #38eeff;

  @media (max-width: 1024px) {
    display: ${props => (props.isResponsive ? "block" : "none")};
    box-shadow: none;
  }
`

export const Icon = styled.img`
  transition: 0.4s;
  width: 1.2rem;
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(325deg);

  @media (max-width: 500px) {
    width: 1.1rem;
  }
`

export const Label = styled.div`
  margin-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ff0070;
  width: 350px;
  height: 2rem;

  &:hover img {
    transform: translateX(-7px);
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`
