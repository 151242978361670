import React from 'react';

export function Bin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="35" viewBox="0 0 48 19">
      <text id="Bin" transform="translate(0 14)" fill="#fff" font-size="18" font-family="roc-grotesk-extrawide-500" font-weight="400"><tspan x="0" y="0">Bin</tspan></text>
    </svg>
  )
}



