import React, { useState, useRef, useEffect } from 'react'
import api from '../../modules/api'
import ToastAnimated from '../Toast'
import { i18n } from '../../translate/i18n'

import {
    ContainerOutside,
    Container,
    ContainerCloseButton,
    CloseButton,
    Heading,
    Title,
    Subtitle,
    ContainerCredentials,
    TitleInput,
    Input,
    ButtonGo,
    LabelMessage,
    IconCloseButton,
    IconArrow,
} from './styled'


//icon
import IconClose from '../../assets/icons/Icon-close.svg'
import Arrow from '../../assets/images/arrowModal.svg'

const ContactModal = ({ isOpenModal, handleClose, OpenPDF }) => {
    const [userData, setUserData] = useState({ email: '', name: '' })
    const [message, setMessage] = useState('')
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    function handleChange(e) {
        const { name, value } = e.target
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
        setMessage('')
    }

    async function sendEmail(e) {
        api.post('/register-email', {
            email: userData.email,
            name: userData.name,
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem(
                        'userCredentials',
                        JSON.stringify(userData)
                    )
                }
                if (response.data.message === 'Email registered successfully') {
                    i18n.language === 'en'
                        ? setMessage('Email registered successfully')
                        : setMessage('Email registrado com sucesso')
                    setTimeout(() => {
                        handleClose()
                        OpenPDF()
                    }, 3000)
                }
            })

            .catch((error) => {
                if (
                    error?.response?.data?.message ===
                    'Email already registered'
                ) {
                    i18n.language === 'en'
                        ? setMessage('Email already registered')
                        : setMessage('E-mail já registrado')
                }
            })

            .catch((error) => console.log(error))
        e.preventDefault()
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (!userData.email) {
            setMessage('preencha o campo e-mail')
            return
        } else if (!userData.name) {
            setMessage('preencha o campo nome')
            return
        } else {
            sendEmail(e)
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            //Function if clicked on outside of element
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose()
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <ContainerOutside isOpenModal={isOpenModal}>
            <Container ref={wrapperRef}>
                <ContainerCloseButton>
                    <CloseButton onClick={() => handleClose()}>
                        <IconCloseButton src={IconClose} />
                    </CloseButton>
                </ContainerCloseButton>
                <Heading>
                    <Title>{isLanguangePt ? 'Contato' : 'Contact'}</Title>
                    <Subtitle>
                        {isLanguangePt ? 
                            'Informe seu contato para ter acesso ao material. Seus dados não serão compartilhados com ninguém.'
                        : 
                            'Inform your contact to have access to the publication. Your data will not be shared with anyone.'
                        }
                        
                    </Subtitle>
                </Heading>
                <ContainerCredentials
                    onSubmit={handleSubmit}
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-netlify-recaptcha="true"
                >

                    <TitleInput>Email</TitleInput>
                    <Input
                        placeholder={isLanguangePt ? "digite seu email aqui" : "Type your email here"}
                        autoComplete="off"
                        value={userData.email}
                        onChange={handleChange}
                        type="email"
                        name="email"
                    />
                    <TitleInput>{isLanguangePt ? 'Nome' : 'Name'}</TitleInput>
                    <Input
                        placeholder={isLanguangePt ? "digite seu nome aqui" : "Type your name here"}
                        autoComplete="off"
                        value={userData.name}
                        onChange={handleChange}
                        type="text"
                        name="name"
                    />
                    <ButtonGo type="submit">
                        {/* <Go /> */}
                        <span>{isLanguangePt ? 'Ir' : 'Go'}</span>
                        <IconArrow src={Arrow} />
                    </ButtonGo>
                </ContainerCredentials>
                <LabelMessage>{message}</LabelMessage>
            </Container>
            <ToastAnimated />
        </ContainerOutside>
    )
}

export default ContactModal
