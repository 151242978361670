import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const ContainerSlidersArtitles = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 150px;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`

export const ContentSectionArticle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
`

export const SwiperContainer = styled(Swiper)`
    width: 100%;

    @media (max-width: 600px) {
        .next {
            background: transparent
                linear-gradient(180deg, #0b161700 0%, #0b1617cf 100%) 0% 0%
                no-repeat padding-box;
            opacity: 0.5;
        }
    }
`

export const StandContainer = styled.div`
    width: 100%;

    display: flex;
    gap: 80px;

    margin: 73px 0;
    padding-left: 70px;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    @media (max-width: 1024px) {
        padding-left: 45px;
    }

    @media (max-width: 690px) {
        padding-left: 23px;
        gap: 40px;
    }
`

export const LeftContainer = styled.div`
    width: 270px;
    height: 551px;
    display: flex;
    flex-direction: column;
    gap: 53px;

    justify-content: center;
    align-items: flex-start;

    @media (max-width: 700px) {
        height: auto;
        gap: 0px;
    }
`

export const StandTitle = styled.p`
    width: 100%;
    
    text-transform: uppercase;
    margin: 0;


    text-align: left;
    font-family: 'roc-grotesk-condensed-600';
    font-weight: 600;
    font-size: 120px;
    line-height: 100px;
    letter-spacing: 6px;
    color: #c2de23;


    @media (max-width: 700px) {
        display: inline;
        font-size: 60px;
        letter-spacing: 2px;
        line-height: 80px;
    }

    @media (max-width: 360px) {
        font-size: 50px;
    }
`

export const StandSubtitle = styled.p`
    text-align: left;
    font: normal normal normal 18px/24px CabinetGrotesk;
    letter-spacing: 0px;
    color: #ffffff;

    max-width: 245px;

    @media (max-width: 700px) {
        max-width: 250px;
        font-size: 20px;
        line-height: 30px;
    }

    @media (max-width: 420px) {
        max-width: 200px;
        font-size: 16px;
        line-height: 24px;
    }
`

export const MaterialCard = styled.div`
    height: 570px;
    min-width: 293px;
    max-width: 293px;
    cursor: pointer;
    padding: 0;
    margin-right: 1rem;

    border-radius: 10px;
    border: none;
    opacity: 1;
    overflow: hidden;

    transition: all 0.2s ease-in-out;
    /* transition-delay: 5s; */
    box-shadow: inset 0px 0px 30px #0b1617;
    background: #0038fa 0% 0% no-repeat padding-box;

    display: flex;
    flex-direction: column;

    @media (max-width: 1440px) {
        width: 100%;
        max-width: 350px;
        flex: 1;
    }
`

export const BoxImage = styled.div`
    width: 100%;
    height: 250px;

    display: flex;
    align-items: flex-end;
    position: relative;

    background: gray;
`

export const ContentStand = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 60px;

    padding-block: 35px;
    padding-inline: 23px;

    &:hover > div:nth-child(1) h3 {
        font-weight: 700;
    }

    &:hover > div:nth-child(2) > div {
        transition: all 0.2s ease-in-out;
        opacity: 1;
    }

    &:hover {
        box-shadow: none;
    }
`

export const ImgCard = styled.img`
    object-fit: cover;
`

export const ArticleNumber = styled.span`
    font-family: 'roc-grotesk';
    font-weight: 300;
    font-size: 90px;
    text-shadow: -1px -1px 0 #0038fa, 1px -1px 0 #0038fa, -1px 1px 0 #0038fa,
        1px 1px 0 #0038fa;
    padding-left: 23px;
    color: #ffffff;
    position: absolute;
    z-index: 11;
`

export const ContentTextArticle = styled.div`
    height: 130px;
    
    span {
        font-family: 'CabinetGrotesk';
        font-weight: 400;
        font-size: 15px;
        opacity: 0.5;
    }
`

export const ArticleTitle = styled.h3`
    font-family: 'CabinetGrotesk';
    font-size: 34px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    transition: all 0.2 ease-in-out;
    max-width: 180px;
`

export const BoxIconArticle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin-top: auto;
`

export const IconArticle = styled.img`
    width: 63px;
    height: 65px;
`

export const ButtonDowloand = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    gap: 10px;
    transition: all 0.2s ease-in-out;
`

export const ImgArrow = styled.img`
    width: 30px;
    height: 30px;
`

export const TextButton = styled.p`
    max-width: 100px;
    font-family: 'CabinetGrotesk';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
`
export const ContainerSlide = styled.div`
    width: auto;
    flex-wrap: nowrap;
    display: flex;
    overflow: auto;
    z-index: 11;

    transition: all 0.3s ease-in-out;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    img {
        object-fit: cover;
    }

    a {
        text-decoration: none;
        color: #fff;
    }
`
