import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// Components
import Layout from "../components/Layout"
import BackbonesDigitals from "../components/ContentbackbonesDigital"

export default function BackbonesDigitais() {
  const query = useStaticQuery(graphql`
    {
      firsti {
        services {
          cards
          knowMore
          knowMorePt
        }
      }
    }
  `)
  const { firsti } = query

  return (
    <Layout seo="1STi | Backbones Digitais">
      <BackbonesDigitals datas={firsti.services[0]} />
    </Layout>
  )
}
