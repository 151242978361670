import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// i18
import { i18n } from "../../translate/i18n"

//hooks
import { useEmailMethods } from '../../Hooks/useEmailMethods'


// Styles
import * as S from './styles'
import Arrow from '../../assets/images/arrow.svg'

//Components
import ContactModal from '../ContactModal'


export function Stand() {
    const [openModal, setOpenModal] = useState(false)
    const [modal, setModal] = useState('')
    //none first animation
    const [noneAnimation, setNoneAnimation] = useState(false)
    const { emails } = useEmailMethods()

    let userData = null

    const data = useStaticQuery(graphql`
        query {
            firsti {
                menuDynamics {
                    anchor
                    title
                    titleEn
                    description
                    descriptionEn
                }

                articlesForDownloads {
                    articleTitle
                    coverImage {
                        url
                    }
                    pdf {
                        url
                    }
                    iconArticle {
                        url
                    }
                }
            }
        }
    `)
    const articlesForDownload = data.firsti.articlesForDownloads

    let pdfWindow = null
    const OpenPDF = (pdfURL, titlePDF) => {
        if (typeof window !== 'undefined') {
            pdfWindow = window.open('documentArticle', titlePDF)
        }
        const title = `1STI - ${titlePDF}`
        const URI = `documentArticle/${titlePDF}`
        const html = `
          <html>
            <head>
              <title>${title}</title>
            </head>
            <body style="margin:0">
              <embed width="100%" height="100%" src=${pdfURL} type="application/pdf">
            </body>
                <script>
                    var span = document.getElementById("title");
                    var el_down = document.getElementById("title");
        
                    function ChangeTitle() {
                        span.textContent = "New Span content";
                        el_down.innerHTML = "Span content changed";
                    }     
                    ChangeTitle()   
                </script>
          </html>
        `

        pdfWindow?.document?.write(html)
        pdfWindow?.document?.close()
        pdfWindow?.history.pushState(null, null, URI)
    }

    function OpenModal(pdfURL, titlePDF) {
        if (typeof window !== 'undefined') {
            userData = window?.localStorage?.getItem('userCredentials')
        }
        const credentials = JSON.parse(userData)

        const hasEmail = emails.find(
            (item) => item.email === credentials?.email
        )
        if (userData && hasEmail) {
            OpenPDF(pdfURL, titlePDF)
        } else {
            setOpenModal(true)
            setNoneAnimation(true)
            setModal(pdfURL)
        }
    }


    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"
    const menuStand = data.firsti.menuDynamics.find(item => item.anchor === "bookcase")

    return (
        <S.StandContainer id="bookcase">
            {noneAnimation && (
                <ContactModal
                    isOpenModal={openModal}
                    handleClose={() => setOpenModal(false)}
                    OpenPDF={() => OpenPDF(modal)}
                />
            )}
            <S.LeftContainer>
                <div>
                    {isLanguangePt ? (
                        <>
                            <S.StandTitle isLanguangePt={isLanguangePt}>ES</S.StandTitle>
                            <S.StandTitle isLanguangePt={isLanguangePt}>TAN</S.StandTitle>
                            <S.StandTitle isLanguangePt={isLanguangePt}>TE</S.StandTitle>
                        </>
                    ) : <S.StandTitle isLanguangePt={isLanguangePt}>Shelf</S.StandTitle>}
                </div>
                <S.StandSubtitle>
                    {isLanguangePt ? menuStand.description : menuStand.descriptionEn}
                </S.StandSubtitle>
            </S.LeftContainer>
            <S.ContainerSlidersArtitles>
                <S.ContainerSlide>
                    {articlesForDownload.map((item, index) => (
                        <S.MaterialCard
                            role="button"
                            onClick={() =>
                                OpenModal(item.pdf?.url, item.articleTitle)
                            }
                            // onClick={() => ValidateEmail()}
                        >
                            {item.coverImage && (
                                <S.BoxImage>
                                    <S.ImgCard src={item.coverImage.url} />
                                    <S.ArticleNumber>
                                        {index + 1}
                                    </S.ArticleNumber>
                                </S.BoxImage>
                            )}

                            <S.ContentStand>
                                <S.ContentTextArticle>
                                    <S.ArticleTitle>
                                        {item.articleTitle}
                                    </S.ArticleTitle>
                                    <span>{!isLanguangePt && 'English version coming soon'}</span>
                                </S.ContentTextArticle>

                                {item.iconArticle && (
                                    <S.BoxIconArticle>
                                        <S.IconArticle src={item.iconArticle.url} />
                                        <S.ButtonDowloand>
                                            <S.ImgArrow src={Arrow} />
                                            <S.TextButton>
                                                {isLanguangePt ?  'Clique para baixar' : 'Click to download'}
                                            </S.TextButton>
                                        </S.ButtonDowloand>
                                    </S.BoxIconArticle>
                                )}
                            </S.ContentStand>
                        </S.MaterialCard>
                    ))}
                </S.ContainerSlide>
            </S.ContainerSlidersArtitles>
        </S.StandContainer>
    )
}
