import styled from 'styled-components'

export const ContainerServices = styled.section`
    width: 100%;
    height: auto;
    background-color: #0b1617;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @media (max-width: 768px) {
    }
`

export const Card = styled.a`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 3.813rem 70px;
    width: 33.22%;
    height: 594px;
    transition: 0.4s;
    text-decoration: none;
    border: 1px solid #0b1617;
    background-color: #000000;
    color: #fff;

    h2 {
        font-size: 1.875rem;
        font-weight: 500;
        @media (max-width: 768px) {
            font-size: 1.5rem;
            width: 100%;
            height: 3.813rem;
        }
        @media (max-width: 452px) {
            height: 5.719rem;
            font-size: 1.2rem;
        }
    }

    &:first-child {
        h3 {
            width: min-content;
        }
    }

    &:hover {
        box-shadow: inset 0px 0px 70px #af39e6;
        p {
            color: #38eeff;
        }
        h2 {
            font-weight: bold;
        }
    }
    @media (max-width: 768px) {
        padding: 3rem 45px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        height: 500px;
        padding-inline: 45px;
        border-bottom: solid 2px #0b1617;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }

    @media (max-width: 420px) {
        height: 450px;
        padding: 2rem 23px;
        display: flex;
        justify-content: center;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    @media (max-width: 420px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
export const CardsContainer = styled.section`
    display: flex;
    width: 100%;

    @media (max-width: 550px) {
        display: flex;
        flex-direction: column;
    }
`

export const BoxText = styled.div`
    width: 100%;
    height: 250px;
    background-color: #000000;
    padding-top: 4rem;
    padding-left: 5%;

    @media (max-width: 1024px) {
        padding-inline: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }

    @media (max-width: 900px) {
        width: 100%;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`

export const Description = styled.p`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0 2.5rem;
    font-size: 1.125rem;
    line-height: 32px;
    font-weight: 500;
    font-family: 'CabinetGrotesk';

    font-size: 22px;
    color: #38eeff;
    max-width: 590px;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`

export const Title = styled.h2`
    width: 100%;
    max-width: 220px;
    font-family: 'GoshaSans';
    font-size: 2.875rem;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: 768px) {
        font-size: 1.875rem;
    }
`

export const TitleCard = styled.h3`
    font-size: 30px;
    width: 100%;
    max-width: 250px;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`

export const DescriptionCard = styled.p`
    display: flex;
    height: 90%;
    width: 100%;
    max-width: 330px;
    margin-top: 60px;
    padding: 0px 0px 15px 0px;
    z-index: 1;
    color: #7e7e7e;

    font-family: 'CabinetGrotesk';
    font-size: 22px;
    font-weight: 400;

    @media (max-width: 860px) {
        margin-top: 30px;
    }

    @media (max-width: 768px) {
        min-height: 40%;
        margin-top: 1rem;
    }
`

export const paragraphCard = styled.p`
    font-family: 'CabinetGrotesk';
    font-size: 22px;
    color: #7e7e7e;

    @media (max-width: 1200px) {
        font-size: 16px;
    }

    @media (max-width: 860px) {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 550px) {
        font-size: 20px;
    }
`
export const DivBlack = styled.div`
    display: none;
    width: 49.7%;
    height: 440px;
    background-color: black;
    border-bottom: solid 3px #0b1617;
    @media (max-width: 768px) {
        display: block;
    }
    @media (max-width: 600px) {
        height: 319px;
    }
`
export const LinkKnowMore = styled.div`
    display: flex;
    gap: 16px;

    img {
        width: 19px;
        cursor: pointer;
        @media (max-width: 768px) {
            width: 15px;
        }
    }
    a {
        color: #af39e6;
        font-size: 1.25rem;
        font-weight: 400;
        text-decoration: none;
        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }

    @media (max-width: 452px) {
        a {
            font-size: 0.9rem;
        }
    }
    @media (max-width: 768px) {
        margin-top: 1.75rem;
    }
`
