import styled from "styled-components"

// Components
import { EstructurePageCards } from "../EstructurePageCards"

export const Container = styled.div`
  width: 100%;
`

export const ContainerHeader = styled.div`
  width: 100%;
  padding-inline: 70px;

  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 690px) {
    padding-inline: 23px;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-wrap: wrap;

  margin: 0 auto;
  margin-top: 8.625rem;
  padding-bottom: 150px;

  position: relative;
  overflow: hidden;

  @media (max-width: 1210px) {
    padding-block: 15px;
    margin-top: 4.625rem;
  }

  @media (min-height: 1100px) {
    @media (min-width: 1100px) {
      margin-top: 4.625rem;
      padding-block: 150px;
    }
  }
`

export const BoxHeander = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  .imagesEn {
    width: 39%;
    display: flex;
    gap: 60px;
    flex-direction: column;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`

export const EstructurePage = styled(EstructurePageCards)`
  .title-box {
    h2 {
      @media (max-width: 768px) {
        max-width: 250px;
        font-size: 2.8rem;
      }
    }
  }
`

export const DBImageBox = styled.div`
  display: ${props => (props.isResponsive ? "none" : "flex")};

  width: 100%;
  max-width: 620px;
  margin-top: ${props => (props.isResponsive ? "32px" : "100px")};
  object-fit: cover;
  align-items: center;
  @media (max-width: 1024px) {
    display: ${props => (props.isResponsive ? "block" : "none")};
  }

  @media (min-height: 1100px) {
    @media (min-width: 1100px) {
      margin-top: 0;
    }
  }
`

export const DBContentContactUsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #742698;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 82px;

  padding: 2rem 0;
  margin-inline: auto;

  button:hover {
    background-color: #0b1617;
    color: #fff;
  }

  h2 {
    width: 100%;
    max-width: 470px;
    font-size: 30px;
    font-weight: 400;
  }

  @media (max-width: 1440px) {
    padding-inline: 70px;
  }

  @media (max-width: 1210px) {
    padding-inline: 40px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
    padding-inline: 20px;

    h2 {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`

export const ContentListCards = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  display: flex;

  @media (max-width: 1030px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const ListCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24.9% 24.95% 24.95% 25%;
  grid-gap: 1px;
  margin-top: 1px;

  @media (max-width: 1030px) {
    display: flex;
    justify-content: end;
    gap: 1px;
  }

  @media (max-width: 1290px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`

export const Card = styled.a`
  width: 100%;
  height: 450px;
  background-color: #000000;
  display: grid;

  padding: 3.813rem 70px;
  transition: 0.4s;
  text-decoration: none;
  color: #fff;

  h2 {
    font-size: 1.875rem;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 18px;
      width: 100%;
    }
  }
  img {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &:hover {
    p {
      color: #38eeff;
    }
    h2 {
      font-weight: bold;
    }

    a {
      color: #af39e6;
    }
    img {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 768px) {
    height: 320px;
  }

  @media (max-width: 690px) {
    padding-left: 23px;
    padding-right: 20px;
    padding-block: 32px;
    height: 280px;
  }
`

export const Title = styled.h2`
  max-width: 80%;
  font-family: GoshaSans;
  font-size: 2.875rem;
  font-weight: bold;
  line-height: 1.2;
`

export const DescriptionCard = styled.p`
  width: 100%;
  max-width: 300px;

  height: 6.5rem;
  margin-top: 2.25rem;
  font-family: CabinetGrotesk;
  font-size: 1.375rem;
  color: #7e7e7e;
  z-index: 1;

  @media (max-width: 768px) {
    margin-top: 0.625rem;
    font-size: 1.2rem;
  }

  @media (max-width: 690px) {
    font-size: 1rem;
  }
`

export const LinkKnowMore = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6.125rem;
  width: 100%;
  gap: 16px;

  img {
    width: 19px;
    cursor: pointer;
    @media (max-width: 768px) {
      width: 15px;
    }
  }
  a {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    text-decoration: none;
    @media (max-width: 768px) {
      color: #af39e6;
      font-size: 0.9rem;
    }
  }
  @media (max-width: 768px) {
    margin-top: 2.9rem;
  }
`
