import React, { useEffect } from 'react'
import * as S from './styled'

const ImagesCarrosel = ({ images }) => {
    useEffect(() => {
        const scrollContainer = document?.getElementById('slide')
        function OnScroll(evt) {
            evt.preventDefault()
            scrollContainer.scrollLeft += evt.deltaY
        }

        scrollContainer.addEventListener('wheel', OnScroll)

        return () => window.removeEventListener('wheel', OnScroll)
    }, [])

    return (
        <S.ContainerSlide id="slide">
            {images?.map((image) => (
                <img src={image.src} alt="" />
            ))}
        </S.ContainerSlide>
    )
}

export default ImagesCarrosel
