import styled from 'styled-components'

export const Container = styled.section`
    background-color: ${(props) => props.theme.colors.backgroundWhoWeAre};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
    min-height: 419px;

    padding: 60px 161px 60px 5%;

    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h2 {
            color: #0b1617;
            font: normal normal bold 46px/50px;
        }

        p {
            color: #0b1617;
            font: normal normal medium 18px/27px Cabinet Grotesk;
            max-width: 560px;
            letter-spacing: 0px;
        }
    }

    figure {
        img {
            width: 500px;
            opacity: 0;
            animation: ${(props) => props.theme.animations.showImage} 0.5s
                ease-in-out;
            animation-fill-mode: forwards;
            /* animation-delay: 2s; */
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        gap: 10px;

        figure {
            img {
                animation: ${(props) => props.theme.animations.showImage} 0.5s
                    ease-in-out;
                width: 100%;
                animation-fill-mode: forwards;
            }
        }
    }

    @media (max-width: 600px) {
        padding-inline: 23px;
    }
`
