import * as S from './styles'
import React from 'react'

// i18
import { i18n } from "../../translate/i18n"


import useWindowDimensions from '../../utils/windowSizeScreen'
import IconAnimation1sti from '../IconAnimation1sti'

const ArticlePageThree = ({ pageContext }) => {
    const { width } = useWindowDimensions()
    let url = null
    
    if (typeof window !== 'undefined') {
        url = window.location.href
    }

    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <S.Container>
            <S.CoverPage
                backgroundCover={pageContext?.backgroundCover?.url}
                backgroundMobile={pageContext?.backgroundMobile?.url}
                width={width}
                title={pageContext?.titleArticle}
                titleEn={pageContext?.titleArticleEn}
                buttonOne="inovação"
                buttonTwo="health"
            />
            

            <S.Content>
                
                <S.ComponentArticleSelect 
                    title={pageContext?.titleArticle}
                    titleEn={pageContext?.titleArticleEn}
                    author={pageContext?.author}
                    linkLinkedin={pageContext?.linkLinkedin}
                />

                <S.ComponentDateSharePrint 
                    hasDate
                    date={pageContext?.articleDate}
                    dateEn={pageContext?.articleDataEn}
                    maxWidth="690px"
                />

                <S.ContainerTexts
                    dangerouslySetInnerHTML={{
                        __html: isLanguangePt ? pageContext?.content?.html : pageContext?.contentEn?.html
                    }}
                />

                <S.ComponentDateSharePrint 
                    maxWidth="800px"
                    date={pageContext?.articleDate}
                    dateEn={pageContext?.articleDataEn}
                    hasDate={false}
                />

                <S.ContainerCredit>
                    <S.BoxCredit>
                        <h5>{isLanguangePt ? 'CRÉDITOS' : 'CREDITS'}</h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: isLanguangePt ? pageContext?.credits?.html : pageContext?.creditsEn?.html
                            }}
                        />
                    </S.BoxCredit>
                </S.ContainerCredit>
            </S.Content>
            <IconAnimation1sti />
        </S.Container>
    )
}

export default ArticlePageThree
