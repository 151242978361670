import React from 'react'

import Layout from '../components/Layout'
import { MenuDynamic } from '../components/MenuDynamic/index.js'
import Cover from '../components/CoverPage'
import { Stand } from '../components/Stand'

import { useStaticQuery, graphql } from 'gatsby'

const Magazine = () => {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                formArticleFiguringOuts(orderBy: updatedAt_DESC) {
                    isCover
                    createdAt
                    backgroundCover {
                        url
                    }
                }
                formArticleZooms(orderBy: updatedAt_DESC) {
                    isCover
                    createdAt
                    backgroundCover {
                        url
                    }
                }
                formArticleThinkingBeyonds(orderBy: updatedAt_DESC) {
                    isCover
                    createdAt
                    backgroundCover {
                        url
                    }
                }
                formArticleTechnicolors(orderBy: updatedAt_DESC) {
                    isCover
                    createdAt
                    backgroundCover {
                        url
                    }
                }
            }
        }
    `)

    const firguringOuts = data.firsti.formArticleFiguringOuts
    const articleZooms = data.firsti.formArticleZooms
    const thinkingBeyonds = data.firsti.formArticleThinkingBeyonds
    const technicolors = data.firsti.formArticleTechnicolors
    const articles = firguringOuts.concat(
        articleZooms,
        thinkingBeyonds,
        technicolors
    )
    const articleCover = articles.filter((item) => item.isCover)

    return (
        <Layout
            seoTitle="Binocular"
            seoImage={articleCover[0]?.backgroundCover?.url}
        >
            <Cover />
            <MenuDynamic />
            <Stand />
        </Layout>
    )
}
export default Magazine
