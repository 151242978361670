import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { i18n } from "../../translate/i18n"
import * as S from "./styled"

export default function DigitalBackbones({ data }) {
  const {
    firsti: {
      contentServices: [
        {
          titleService,
          titleServicePt,
          subtitle,
          subtitlePt,
        },
      ],
    },
  } = useStaticQuery(graphql`
    query MyQueryServices {
      firsti {
        contentServices {
          titleService
          titleServicePt
          subtitle
          subtitlePt
          imagePng {
            url
          }
          imageSvg {
            url
          }
        }
      }
    }
  `)

  i18n.addResourceBundle("en", "translations", data)
  i18n.addResourceBundle("pt", "translations", data)

  return (
    <S.DBContainer>
      <S.DBHeader>
        <S.ContentHeader>
          <S.Space>
            <S.DBTitleBox>
              <S.DBMainTitle>
                {i18n.language === "pt-BR" ? titleServicePt : titleService}
              </S.DBMainTitle>
              <S.DBDescription>
                {i18n.language === "pt-BR" ? subtitlePt : subtitle}
              </S.DBDescription>
            </S.DBTitleBox>
          </S.Space>
        </S.ContentHeader>
      </S.DBHeader>
      <S.ContentListCards>
        <S.BoxFirstsCard>
          {i18n.language === "pt-BR" &&
            data.cards.cardPt.map((item, i) => {
                return (
                  <S.CardServicese
                    key={i}
                    title={item.title}
                    subscription={item.text}
                    link={item.link}
                  />
                )
            })}
        </S.BoxFirstsCard>
        <S.BoxFirstsCard>
          {i18n.language === "en-US" &&
            data.cards.card.map((item, i) => {
                return (
                  <S.CardServicese
                    key={i}
                    titleEn={item.title}
                    subscription={item.text}
                    link={item.link}
                  />
                )
              
            })}
        </S.BoxFirstsCard>
      </S.ContentListCards>
    </S.DBContainer>
  )
}
