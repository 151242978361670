import React from 'react'
import SectionLeftHome from '../TextLeftSections'
import { Container } from './styles'
import { i18n } from '../../translate/i18n'

export default function WhoWeAre({ data }) {
    const { title, titlePt, description, descriptionPt, image } = data
    return (
        <Container>
            <div>
                <SectionLeftHome
                    title={i18n.language === 'pt-BR' ? titlePt : title}
                    text={
                        i18n.language === 'pt-BR' ? descriptionPt : description
                    }
                />
            </div>
            <figure>
                <img src={image.url} alt="imagem de circulos rosa e ciano" />
            </figure>
        </Container>
    )
}
