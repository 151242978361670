import React from 'react'
import { Link } from 'gatsby'
import * as S from './styles'

//components
import TextLeftSections from "../../TextLeftSections/index"

//i18n
import { i18n } from '../../../translate/i18n'

export default function JourneysActive({ data }) {
    i18n.addResourceBundle('en', 'translations', data)
    i18n.addResourceBundle('pt', 'translations', data)

    return (
        <S.Container id="jorneys">
            <S.ContentWrapper>
                <S.Journeys>
                    <S.Left>
                        <TextLeftSections
                            title={i18n.t(
                                i18n.language === 'pt-BR' ? 'titlePt' : 'title'
                            )}
                            text={i18n.t(
                                i18n.language === 'pt-BR' ? 'textPt' : 'text'
                            )}
                            textButton="/contact"
                        />
                        <Link to="/contact">
                            <S.Btn>
                                {i18n.t(
                                    i18n.language === 'pt-BR'
                                        ? 'textButtonPt'
                                        : 'textButton'
                                )}
                            </S.Btn>
                        </Link>
                    </S.Left>
                    <S.Right>
                        <S.Education>
                            <S.Title>
                                {i18n.t(
                                    i18n.language === 'pt-BR'
                                        ? 'Educação'
                                        : 'Education'
                                )}
                            </S.Title>
                            <S.LineCyan></S.LineCyan>
                            <S.Institutions>
                                <S.Cogna src={data.cognaImg.url} />
                                <S.Somos src={data.somosImg.url} />
                                <S.Cruzeiro src={data.cruzeiroImg.url} />
                                <S.Laureate src={data.laureateImg.url} />
                            </S.Institutions>
                        </S.Education>

                        <S.InstitutionsTwo>
                            <S.Health>
                                <S.Title>
                                    {i18n.t(
                                        i18n.language === 'pt-BR'
                                            ? 'Saúde'
                                            : 'Health'
                                    )}
                                </S.Title>
                                <S.LineCyan></S.LineCyan>
                                <S.Sirio src={data.sirioImg.url} />
                            </S.Health>

                            <S.Insurance>
                                <S.Title>
                                    {i18n.t(
                                        i18n.language === 'pt-BR'
                                            ? 'Seguros'
                                            : 'Insurance'
                                    )}
                                </S.Title>
                                <S.LineCyan></S.LineCyan>
                                <S.Sulamerica src={data.sulAmericaImg.url} />
                            </S.Insurance>

                            <S.Retail>
                                <S.Title>
                                    {i18n.t(
                                        i18n.language === 'pt-BR'
                                            ? 'Varejo'
                                            : 'Retail'
                                    )}
                                </S.Title>
                                <S.LineCyan></S.LineCyan>
                                <S.Marisa src={data.marisaImg.url} />
                            </S.Retail>
                        </S.InstitutionsTwo>
                    </S.Right>
                </S.Journeys>
            </S.ContentWrapper>
        </S.Container>
    )
}
