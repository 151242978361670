import React from "react"
import * as S from "./styled"

//i18n
import { i18n } from "../../../translate/i18n"

// assets
import Arrow from "../../../assets/images/arrow-icon.png"

// api
import api from "../../../modules/api"

// components
import ToastAnimated, { showToast } from "../../Toast"
import SubscribeButton from "../../SubscribeButton"
import Loading from "../../Loading/index.js"

export default function Attention({ data }) {
  const [form, setForm] = React.useState({
    email: "",
  })

  const [open, setOpen] = React.useState(false)

  i18n.addResourceBundle("en", "translations", data)
  i18n.addResourceBundle("pt", "translations", data)

  const loading = () => {
    setOpen(true)
  }

  const handleToggle = () => {
    setOpen(!open)

    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  const handleChange = e => {
    const { value, name } = e.target

    const updateForm = {
      ...form,
      [name]: value,
    }

    setForm(updateForm)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setOpen(true)
    api
      .post("/register-email", {
        email: form.email,
      })
      .then(response => {
        if (response.status === 200) {
          setForm({
            email: "",
          })
        }
        if (response.data.message === "Email registered successfully") {
          i18n.language === "en-US"
            ? showToast({
                message: response?.data?.message,
                type: "success",
              })
            : showToast({
                message: "Email registrado com sucesso!",
                type: "success",
              })
        }
      })
      .catch(error => {
        setOpen(false)
        if (error?.response?.data?.message === "Email already registered") {
          i18n.language === "en-US"
            ? showToast({
                message: error?.response?.data?.message,
                type: "error",
              })
            : showToast({
                message: "E-mail já registrado",
                type: "error",
              })
        }
        if (error?.response?.data?.message === "Email is required") {
          i18n.language === "en-US"
            ? showToast({
                message: error?.response?.data?.message,
                type: "error",
              })
            : showToast({
                message: "Email obrigatório",
                type: "error",
              })
        }
      })
  }

  return (
    <S.ContainerAttention>
      <S.AttentionWrapper>
        <S.Box onSubmit={handleSubmit}>
          <S.Title>
            {i18n.t(i18n.language === "pt-BR" ? "titlePt" : "title")}
          </S.Title>
          <S.Description>
            {i18n.t(i18n.language === "pt-BR" ? "paragraphPt" : "paragraph")}
          </S.Description>
          <S.Label>
            <S.NewsLetter
              type="email"
              name="email"
              value={form.email}
              autoComplete="off"
              onChange={handleChange}
              placeholder={i18n.t(
                i18n.language === "pt-BR" ? "Digite seu email" : "Enter your email"
              )}
            />
            <SubscribeButton onClick={handleToggle} type="submit">
              <S.Icon src={Arrow} alt="Arrow icon" />
            </SubscribeButton>
            {open && <Loading onChange={loading} />}
          </S.Label>
          <ToastAnimated />
        </S.Box>
      </S.AttentionWrapper>
    </S.ContainerAttention>
  )
}
