import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styles'

// Components

import Button from '../Button/index'

// i18
import { i18n } from '../../translate/i18n'

export function ContentDeepAnalytics({ datas }) {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                pageDeepAnalytics {
                    titlePt
                    title
                    subTitle
                    subTitlePt
                    decriptionOne
                    decriptionOnePt
                    decriptionTwo
                    decriptionTwoPt
                    imagePt {
                        url
                    }
                    imageEn {
                        url
                    }
                    imageMobPt {
                        url
                    }
                    imageMobEn {
                        url
                    }
                    iconPlus {
                        url
                    }
                }

                contentCard {
                    titleContactUs
                    titleContactUsPt
                    textButtonContactUs
                    textButtonContactUsPt
                }
            }
        }
    `)

    const {
        titleContactUs,
        titleContactUsPt,
        textButtonContactUs,
        textButtonContactUsPt,
    } = data.firsti.contentCard[0]

    const {
        titlePt,
        title,
        subTitle,
        subTitlePt,
        decriptionOne,
        decriptionOnePt,
        decriptionTwo,
        decriptionTwoPt,
        imagePt,
        imageEn,
        imageMobPt,
        imageMobEn,
        iconPlus,
    } = data.firsti.pageDeepAnalytics[0]

    i18n.addResourceBundle('en', 'translations', data)
    i18n.addResourceBundle('pt', 'translations', data)

    const link = () => {
        const url = datas.cards.cardPt.map((item) => {
            return item.id
        })
        if (url.length > 0) {
            document.getElementById(5).style.display = 'none'
        }
    }

    useEffect(() => {
        link()
    }, [])

    return (
        <S.Container>
            <S.ContainerHeader>
                <S.Content>
                    <S.BoxHeander>
                        <S.EstructurePage
                            title={title}
                            titlePt={titlePt}
                            subtitlePt={subTitlePt}
                            subtitle={subTitle}
                            description={decriptionOne}
                            descriptionPt={decriptionOnePt}
                            descriptionTwo={decriptionTwo}
                            descriptionTwoPt={decriptionTwoPt}
                        >
                            {i18n.language === 'pt-BR' ? (
                                <S.DBImageBox isResponsive>
                                    <img src={imageMobPt.url} alt="" />
                                </S.DBImageBox>
                            ) : (
                                <S.DBImageBox isResponsive>
                                    <img src={imageMobEn.url} alt="" />
                                </S.DBImageBox>
                            )}
                        </S.EstructurePage>
                        {i18n.language === 'pt-BR' ? (
                            <S.DBImageBox>
                                <img src={imagePt.url} alt="" />
                            </S.DBImageBox>
                        ) : (
                            <S.DBImageBox>
                                <img src={imageEn.url} alt="" />
                            </S.DBImageBox> 
                        )}
                    </S.BoxHeander>
                </S.Content>
            </S.ContainerHeader>
            <div>
                <S.LinePurple></S.LinePurple>
                <S.DBContentContactUsWrapper>
                    <h2>
                        {i18n.language === 'pt-BR'
                            ? titleContactUsPt
                            : titleContactUs}
                    </h2>
                    <Button to="/contact">
                        {i18n.language === 'pt-BR'
                            ? textButtonContactUsPt
                            : textButtonContactUs}
                    </Button>
                </S.DBContentContactUsWrapper>
                <S.ContentListCards>
                    <S.ListCard>
                        {i18n.language === 'pt-BR'
                            ? datas.cards.cardPt.map((item, i) => {
                                  return (
                                      <>
                                          <S.Card
                                              key={i}
                                              id={item.id}
                                              href={item.link}
                                          >
                                              <S.Title>{item.title}</S.Title>
                                              <S.DescriptionCard>
                                                  {item.text}
                                              </S.DescriptionCard>
                                              <S.LinkKnowMore>
                                                  <a href={item.link}>
                                                      {datas.knowMorePt}
                                                  </a>
                                                  <img
                                                      src={iconPlus.url}
                                                      alt="Icon Plus"
                                                  />
                                              </S.LinkKnowMore>
                                          </S.Card>
                                      </>
                                  )
                              })
                            : datas.cards.card.map((item, i) => {
                                  return (
                                      <>
                                          <S.Card
                                              key={i}
                                              id={item.id}
                                              href={item.link}
                                          >
                                              <S.Title>{item.title}</S.Title>
                                              <S.DescriptionCard>
                                                  {item.text}
                                              </S.DescriptionCard>
                                              <S.LinkKnowMore>
                                                  <a href={item.link}>
                                                      {datas.knowMore}
                                                  </a>
                                                  <img
                                                      src={iconPlus.url}
                                                      alt="Icon Plus"
                                                  />
                                              </S.LinkKnowMore>
                                          </S.Card>
                                      </>
                                  )
                              })}
                    </S.ListCard>
                </S.ContentListCards>
            </div>
        </S.Container>
    )
}
