import styled from "styled-components"

export const DBContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #0b1617;
  overflow: hidden;
  z-index: 0;
`

export const DBWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;

  display: flex;
  margin-top: 50px;
  gap: 95px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`

export const DBHeader = styled.header`
  width: 100%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 70px;
  padding-bottom: 150px;

  margin-top: 8.625rem;
  margin-inline: auto;

  @media (max-width: 1210px) {
    padding-block: 15px;
    margin-top: 4.625rem;
  }

  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 690px) {
    padding-inline: 23px;
  }
`

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 1.75rem 4.563rem 0 4.563rem; */
  width: 100%;
  height: 100%;
`

export const Texts = styled.div`
  width: 100%;
  max-width: 510px;

  & > p {
    margin-bottom: 20px;
    font-family: 'CabinetGrotesk';
    font-size: 18px;
    line-height: 30px;
  }

  .boxContent {
    margin-bottom: 40px;

    h1 {
      font-size: 30px;
      font-family: 'GoshaSans-Bold';
      margin-bottom: 2px;
    }

    p {
      strong {
        width: 100%;
        max-width: 550px;
  
        font-family: 'CabinetGrotesk';
        font-size: 18px;
        margin-bottom: 32px;
      }
    }
  }

  strong {
    display: block;
  }

  @media(max-width: 1024px) {
    margin-top: 35px;
  }

`

// Text about the cards
export const DBAbout = styled.section`
  padding-top: 0.8rem;
  font-weight: 500;

  p,
  ul {
    max-width: 480px;
  }
`

export const DBTitleBox = styled.div`
  padding-bottom: 5rem;

  & > h3 {
    height: 150px;
    color: #00c8db;
  }

  @media (max-width: 768px) {
    padding-bottom: 2rem;

    & > h3 {
      font-size: 1.3rem;
    }
  }
`

export const DBMainTitle = styled.h2`
  width: 100%;
  max-width: ${({ language }) => language && '460px'};

  font-size: 4rem;
  font-weight: bold;
  font-family: GoshaSans;

  
  @media (max-width: 768px) {
    line-height: 3rem;
    font-size: 2.5rem;
  }
`

export const DBDescription = styled.h4`
  max-width: 540px;

  font-size: 1.875rem;
  font-weight: 600;
  line-height: 38px;
  font-family: CabinetGrotesk;

  color: #38eeff;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 420px;
    margin: 0;
    font-weight: 400;
    padding-top: 0.5rem;
    line-height: 26px;
    font-size: 1.3rem;
  }
`

export const DBSubtitle = styled.h3`
  margin: 1.5rem 0 2rem;
  font-size: 1.3em;
  font-weight: 400;
`

export const DBParagraph = styled.p`
  font-size: 18px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  line-height: 1.8rem;
  /* font-size: 1em; */

  span {
    padding-top: 30px;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

export const DBImageBox = styled.div`
  width: 100%;

  h3 {
    color: #00c8db;
  }
  img {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
export const DBContentContactUs = styled.div`
  border-top: 1px solid #742698;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DBContentContactUsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 92px;
  padding: 2rem 0 3rem 0;

  button:hover {
    background-color: #0b1617;
    color: #fff;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 12px;
    text-align: center;
    button {
      font-size: 1rem;
    }
  }
`

export const DBContentContactUsTitle = styled.section`
  h2 {
    font-size: 2.5rem;
    font-weight: 400;
    font-size: 30px;
    max-width: 470px;

    div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      width: 100%;
      max-width: 358px;
      text-align: center;
    }
  }
`
export const ContainerServices = styled.section`
  width: 100%;
  height: auto;
  background-color: #0b1617;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem;
`

export const ContentListCards = styled.div`
  width: 100%;
  display: grid;
  margin-bottom: 60px;

  @media (max-width: 1030px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

// imagem mobile

export const ImageMobile = styled.img`
  width: 50%;
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`
export const ListCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  margin-top: 1px;

  @media (max-width: 1030px) {
    display: flex;
    justify-content: end;
    gap: 1px;
  }

  @media (max-width: 1290px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 540px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const Card = styled.a`
  width: 100%;
  height: 450px;
  background-color: #000000;
  display: ${props => props.invisible ? 'none': 'flex'};
  flex-direction: column;

  padding: 3.813rem 70px;
  transition: 0.4s;
  text-decoration: none;
  color: #fff;



  h2 {
    font-size: 1.875rem;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      width: 100%;
    }
  }

  img {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  &:hover {
    p {
      color: #38eeff;
    }
    h2 {
      font-weight: bold;
    }

    a {
      color: #af39e6;
    }
    img {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    padding-inline: 45px;
  }

  @media (max-width: 750px) {
    padding-block: 32px;
  }

  @media (max-width: 690px) {
    padding-left: 23px;
    padding-right: 20px;
    height: 350px;
  }
`

export const Title = styled.h2`
  max-width: 80%;
  font-family: GoshaSans;
  font-size: 2.875rem;
  font-weight: bold;
  line-height: 1.2;
`

export const DescriptionCard = styled.p`
  width: 100%;
  max-width: 600px;

  height: 6.5rem;
  margin-top: 2.25rem;
  font-family: CabinetGrotesk;
  font-size: 1.375rem;
  color: #7e7e7e;
  z-index: 1;

  @media(max-width: 900px) {
    height: auto;
    font-size: 1.2rem;
  }


  @media (max-width: 690px) {
    font-size: 1rem;
  }
`

export const LinkKnowMore = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  width: 100%;
  gap: 16px;

  img {
    width: 19px;
    display: block;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 15px;
    }
  }

  a {
    color: #af39e6;
    font-size: 1.25rem;
    font-weight: 400;
    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`
