import React, { useEffect, useState } from 'react'
import * as S from './styled'

import SoundIcon from '../../../assets/icons/sound.svg'
import SoundWaves from '../../../assets/icons/soundWaves.svg'

import { i18n } from '../../../translate/i18n'

const isBrowser = typeof window !== 'undefined'

export const isDesktop = () => {
    if (!isBrowser) {
        return
    }

    return window.innerWidth > 768
}

export default function CallToAction({ data }) {
    const [isAppearing, setAppearing] = useState(false)
    const [videoIsWithSound, setVideoIsWithSound] = useState()

    const isMobile = () => {
        if (!isBrowser) {
            return
        }

        return window.innerWidth < 768
    }

    const backText = () => {
        const ctVideo = document.getElementById('ct-video')
        if (isAppearing === true) {
            setAppearing(false)
            ctVideo.pause()
        }
    }

    //função de pause no vídeo mobile
    const pauseVideoMobile = () => {
        const ctVideoMobile = document.getElementById('ct-videomobile')
        if (ctVideoMobile.requestFullscreen) {
            ctVideoMobile.pause()
        }
    }

    i18n.addResourceBundle('en', 'translations', data)
    i18n.addResourceBundle('pt', 'translations', data)

    const hideDiv = () => {
        const div = document.getElementById('box-descktop')
        const divMobile = document.getElementById('box-mobile')

        if (isMobile()) {
            div.style.display = 'none'
            divMobile.style.display = 'block'
        }

        if (isDesktop()) {
            div.style.display = 'block'
            divMobile.style.display = 'none'
        }
    }

    function NavigateFromArticle() {
        if (isBrowser) {
            localStorage.setItem('activeItem', 'Revista')
        }
    }

    useEffect(() => {
        hideDiv()
    }, [])

    return (
        <S.CTAContainer onClick={() => backText()}>
            <S.BGVideo id="ct-video" autoPlay muted={!videoIsWithSound} loop>
                <source src={data.video.url} type="video/mp4" />
            </S.BGVideo>

            <S.BGVideoMobile
                onClick={() => pauseVideoMobile()}
                id="ct-videomobile"
                autoPlay
                muted={!videoIsWithSound}
                loop
            >
                <source src={data.video.url} type="video/mp4" />
            </S.BGVideoMobile>
            <S.ShadowMobile></S.ShadowMobile>
            <S.CTAWrapper className={isAppearing ? 'ChangesTexts' : null}>
                <S.CTAContent>
                    <S.BoxTitleHome id="box-descktop">
                        {i18n.language === "pt-BR" ? (
                            <>
                                <S.PortugueseTitle>
                                    Tecnologia
                                </S.PortugueseTitle>
                                <S.PortugueseTitle>
                                    para impacto
                                </S.PortugueseTitle>
                            </>
                        ) : (
                            <>
                                <S.EnglishTitle>Technology </S.EnglishTitle>
                                <S.EnglishTitle>for impact</S.EnglishTitle>
                            </>
                        )}
                    </S.BoxTitleHome>
                    <S.BoxTitleHome id="box-mobile">
                        {i18n.language === 'pt-BR' ? (
                            <S.PortugueseTitle>
                                Tecnologia{' '}
                                <S.PortugueseTitle>
                                    para impacto
                                </S.PortugueseTitle>
                            </S.PortugueseTitle>
                        ) : (
                            <S.EnglishTitle>
                                Technology{' '}
                                <S.EnglishTitle>for Impact</S.EnglishTitle>
                            </S.EnglishTitle>
                        )}
                    </S.BoxTitleHome>

                    <S.CTATextBox>
                        <S.BoxTextAbout>
                            <S.CTAAbout>
                                {i18n.t(
                                    i18n.language === 'pt-BR' ? 'aboutPt' : 'about'
                                )}
                            </S.CTAAbout>
                        </S.BoxTextAbout>
                        <S.CTAOptions>
                            <S.Div>
                                <S.ButtonOne
                                    to={data.linkToButtonOne}
                                    onClick={NavigateFromArticle}
                                >
                                    {i18n.t(
                                        i18n.language === 'pt-BR'
                                            ? 'textButtonOnePt'
                                            : 'textButtonOne'
                                    )}
                                </S.ButtonOne>
                                <S.ButtonTwo
                                    onClick={() =>
                                        setVideoIsWithSound(!videoIsWithSound)
                                    }
                                >
                                    {i18n.t(
                                        i18n.language === 'pt-BR'
                                            ? !videoIsWithSound
                                                ? 'Som desligado'
                                                : 'Som ligado'
                                            : !videoIsWithSound
                                            ? 'Sound off'
                                            : 'Sound on'
                                    )}
                                    <img
                                        src={SoundIcon}
                                        alt={
                                            i18n.language === 'pt-BR'
                                                ? 'icone de som'
                                                : 'sound icon'
                                        }
                                    />
                                    {videoIsWithSound ? (
                                        <img
                                            src={SoundWaves}
                                            alt={
                                                i18n.language === 'pt-BR'
                                                    ? 'ondas sonoras'
                                                    : 'sound waves'
                                            }
                                        />
                                    ) : null}
                                </S.ButtonTwo>
                            </S.Div>
                        </S.CTAOptions>
                    </S.CTATextBox>
                </S.CTAContent>
            </S.CTAWrapper>
        </S.CTAContainer>
    )
}
