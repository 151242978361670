import styled from 'styled-components'
import { Wrapper } from '../../styles/Wrapper'
// import BackgroundCover from '../../assets/images/cover-revista.png'
// import BackgroundMobile from '../../assets/images/background-mobile.png'

export const Container = styled.div`
    height: 100%;
    a {
        text-decoration: none;
    }
`

export const Header = styled.header`
    width: 100%;
    height: calc(100vh - 4.625rem);
    background-image: ${(props) => `url(${props.backgroundCover})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media (max-width: 428px) {
        background-image: ${(props) => `url(${props.backgroundMobile})`};
        height: 670px;
        background-attachment: scroll;
    }
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 3.25rem;
    z-index: 2;
`

export const BoxLogo = styled.div`
    max-width: 1440px;
    margin: 0 auto;
`

export const BoxBinocular = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 420px;
    margin-top: 85px;

    button {
        background: none;
        border: none;
        width: 100%;
    }

    img {
        transition: 0.3s ease-out;

        @media (max-width: 428px) {
            width: 250px;
        }
    }

    img:hover {
        filter: brightness(100%) invert(100%)
            drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.2));
    }
`

export const ContainerArticle = styled(Wrapper)`
    height: 65vh;
    z-index: 5;

    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(57, 57, 57, 0.608) 47%,
        rgb(23, 23, 23) 100%
    );

    color: #fff;

    display: flex;
    align-items: flex-end;

    h2 {
        font-size: 3rem;
        font-family: 'CabinetGrotesk';
        font-weight: 400;
        line-height: 50px;
        width: 100%;
        max-width: 800px;

        @media (max-width: 900px) {
            font-size: 2.2rem;
            max-width: 800px;
        }

        @media (max-width: 428px) {
            font-size: 2.8rem;
            max-width: 300px;
        }
    }

    span {
        font-family: 'CabinetGrotesk';
        font-weight: 500;
        font-size: 1.5rem;
        font-size: 24px;
        letter-spacing: 0.24px;
    }

    div {
        width: 100%;
        margin: 0 auto;

        display: flex;
        border-top: 1px solid #171717;
        gap: 130px;
        padding-block: 30px;
        margin-bottom: 50px;
        align-items: center;

        @media ${(props) => props.theme.medias.lg} {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
    }
`
