import React from 'react'
import * as S from './styled'

import { Wrapper } from '../../styles/Wrapper'
import { useStaticQuery, graphql, Link } from 'gatsby'

// i18
import { i18n } from "../../translate/i18n"

const Cover = () => {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                coverMagazines {
                    titleMagazine
                }
                formArticleFiguringOuts(orderBy: updatedAt_DESC) {
                    id
                    isCover
                    createdAt
                    titleArticle
                    titleArticleEn
                    typePage
                    backgroundCover {
                        url
                    }
                    backgroundMobile {
                        url
                    }
                }
                formArticleZooms(orderBy: updatedAt_DESC) {
                    id
                    isCover
                    createdAt
                    titleArticle
                    titleArticleEn
                    typePage
                    backgroundCover {
                        url
                    }
                    backgroundMobile {
                        url
                    }
                }
                formArticleThinkingBeyonds(orderBy: updatedAt_DESC) {
                    id
                    isCover
                    createdAt
                    titleArticle
                    titleArticleEn
                    typePage
                    backgroundCover {
                        url
                    }
                    backgroundMobile {
                        url
                    }
                }
                formArticleTechnicolors(orderBy: updatedAt_DESC) {
                    id
                    isCover
                    createdAt
                    titleArticle
                    titleArticleEn
                    typePage
                    backgroundCover {
                        url
                    }
                    backgroundMobile {
                        url
                    }
                }
            }
        }
    `)
        
    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    const firguringOuts = data.firsti.formArticleFiguringOuts
    const articleZooms = data.firsti.formArticleZooms
    const thinkingBeyonds = data.firsti.formArticleThinkingBeyonds
    const technicolors = data.firsti.formArticleTechnicolors
    const articles = firguringOuts.concat(
        articleZooms,
        thinkingBeyonds,
        technicolors
    )
    const articleCover = articles.filter((item) => item.isCover)
    const date = new Date(articleCover[0].createdAt)
    const dateFormated = new Intl.DateTimeFormat(isLanguangePt ? 'pt-BR' : 'en-US').format(date)


    return (
        <S.Container>
            <Link
                to={
                    '/article/' +
                    articleCover[0].typePage +
                    '/' +
                    articleCover[0].id
                }
            >
                <S.Header
                    backgroundCover={articleCover[0]?.backgroundCover?.url}
                    backgroundMobile={articleCover[0]?.backgroundMobile?.url}
                >
                    <S.Content>
                        <Wrapper></Wrapper>
                        <S.ContainerArticle>
                            <div>
                                <span>{dateFormated}</span>
                                <h2>{isLanguangePt ? articleCover[0].titleArticle : articleCover[0].titleArticleEn}</h2>
                            </div>
                        </S.ContainerArticle>
                    </S.Content>
                </S.Header>
            </Link>
        </S.Container>
    )
}

export default Cover
