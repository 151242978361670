import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Styles
import * as S from './styles'

//components
import { ContentServive } from '../../ContentService'

export default function DigitalPlatform() {
    const data = useStaticQuery(graphql`
        query {
            firsti {
                digitalPlatformsPages {
                    title
                    titlePt
                    subtitle
                    subtitlePt
                    titleContactUs
                    titleContactUsPt
                    textButtonContactUs
                    textButtonContactUsPt
                    texts {
                        html
                    }
                    textsPt {
                        html
                    }

                    imagePt {
                        url
                    }
                    imageEn {
                        url
                    }
                    imageMobPt {
                        url
                    }
                    imageMobEn {
                        url
                    }
                    iconPlus {
                        url
                    }
                }
            }
        }
    `)

    const sustainableTechnologies = data.firsti.digitalPlatformsPages[0]

    return (
        <S.DBContainer>
            <ContentServive
                dataService={sustainableTechnologies}
            />
        </S.DBContainer>
    )
}
