import React from 'react'

// Components
import { ContentCover } from '../ContentCover'
import ContentArticle from '../CoverContentLeftText'

// i18
import { i18n } from '../../translate/i18n'

// Styles
import * as S from './styles'

export function SectionArticleTexts({ pageContext }) {

    let url = null
    if (typeof window !== 'undefined') {
        url = window.location.href
    }

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === 'pt-BR'

    return (
        <S.ContainerSection>
            <ContentCover
                backgroundCover={pageContext?.backgroundCover?.url}
                background={pageContext?.backgroundCoverGradient}
                backgroundMobile={pageContext?.backgroundMobile?.url}
                title={pageContext?.titleArticle}
                titleEn={pageContext?.titleArticleEn}
                buttonOne={pageContext?.buttons[0]?.label}
                buttonTwo={pageContext?.buttons[1]?.label}
                imageCenter={pageContext?.imageCover?.url}
            />

            <ContentArticle
                title={pageContext?.titleArticle}
                titleEn={pageContext?.titleArticleEn}
                author={pageContext?.author}
                image={pageContext?.imageContentArticle?.url}
                linkLinkedin={pageContext?.linkLinkedin}
                date={pageContext?.articleDate}
                dateEn={pageContext?.articleDataEn}
                content={pageContext?.content?.html}
                contentEn={pageContext?.contentEn?.html}
            >
                <S.Description>
                    <img src={pageContext?.imgAuthor?.url} alt="Imagem do author" />
                    <p>{isLanguangePt ? pageContext?.commentedAuthor : pageContext?.commentedAuthorEn}</p>
                </S.Description>
            </ContentArticle>
        </S.ContainerSection>
    )
}
