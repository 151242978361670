import React from "react"
import styled from "styled-components"

const Title = styled.h2`
  font-size: 2.875rem;
  font-family: GoshaSans;
  line-height: 3.3rem;
  @media (max-width: 1266px) {
    font-size: 2.3rem;
  }
`
const Text = styled.p`
  margin: 2.25rem 0 1.5rem 0;
  font-size: 22px;
  line-height: 30px;
  font-family: CabinetGrotesk;
  @media (max-width: 1266px) {
    font-size: 1rem;
  }
`

export default function SectionLeftHome(props) {
  return (
    <>
      <Title>{props.title}</Title>
      <Text>{props.text}</Text>
    </>
  )
}
