import React from 'react'
import * as S from './styles.js'

// i18n
import { i18n } from '../../translate/i18n'

//images
import IconPlus from '../../assets/images/plus_icon.svg'
import { Link } from 'gatsby'

export default function Services({ title, titleEn, subscription, link, className }) {
    return (
        <S.ContainerServices href={link} className={className}>
            <S.ContentServices>
                <S.BoxTexts>
                    <S.TitleServices id='titlePt'>{title}</S.TitleServices>
                    <S.TitleServicesEnglish>{titleEn}</S.TitleServicesEnglish>
                    <S.Description>{subscription}</S.Description>
                </S.BoxTexts>

                <S.LinkKnowMore>
                    <Link to={link}>
                        {i18n.t(
                            i18n.language === 'pt-BR' ? 'Saiba mais' : 'Know more'
                        )}
                    </Link>
                    <img src={IconPlus} alt="Ícone de um +" />
                </S.LinkKnowMore>
            </S.ContentServices>
        </S.ContainerServices>
    )
}
