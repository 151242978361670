import React from "react"
import Layout from "../components/Layout"

import DigitalPlatform from "../components/ContentCards/DigitalPlatforms/index.js"

const ContentCardsPage = () => {
  return (
    <Layout seo="1STi | Digital Platforms">
      <DigitalPlatform />
    </Layout>
  )
}

export default ContentCardsPage
