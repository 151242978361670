import styled from "styled-components"

export const ArticlePrintButton = styled.button`
    display: flex;
    gap: 10px;
    border: none;
    background: transparent;

    opacity: 1;

    cursor: pointer;

    p {
        text-align: left;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #0b1617;
    }

    img {
        width: 20px;
        height: 20px;
    }
`