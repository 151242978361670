import React, { useEffect } from "react"
import * as S from "./styles"
import { useStaticQuery, graphql } from "gatsby"

// i18n
import { i18n } from "../../translate/i18n"

//components
import Button from "../Button"

export function ContentDeepTechInovation({ datas }) {
  const data = useStaticQuery(graphql`
    query {
      firsti {
        pageDeepTechInovations {
          titlePt
          title
          subTitle
          subTitlePt
          decriptionOne
          decriptionOnePt
          decriptionTwo
          decriptionTwoPt
          imageTopPt {
            url
          }
          imageDownPt {
            url
          }
          imageDeepTechWebPt {
            url
          }
          imageDownMobEn {
            url
          }
          imageTopEn {
            url
          }
          imageDownEn {
            url
          }
          iconPlus {
            url
          }
        }

        contentCard {
          titleContactUs
          titleContactUsPt
          textButtonContactUs
          textButtonContactUsPt
        }
      }
    }
  `)

  const {
    titleContactUs,
    titleContactUsPt,
    textButtonContactUs,
    textButtonContactUsPt,
  } = data.firsti.contentCard[0]

  const {
    titlePt,
    title,
    subTitle,
    subTitlePt,
    decriptionOne,
    decriptionOnePt,
    decriptionTwo,
    decriptionTwoPt,
    imageTopPt,
    imageDownPt,
    imageDeepTechWebPt,
    imageDownMobEn,
    imageTopEn,
    imageDownEn,
    iconPlus,
  } = data.firsti.pageDeepTechInovations[0]

  i18n.addResourceBundle("en", "translations", data)
  i18n.addResourceBundle("pt", "translations", data)

  const link = () => {
    const url = datas.cards.cardPt.map(item => {
      return item.id
    })

    if (url.length > 0) {
      document.getElementById(4).style.display = "none"
    }
  }

  useEffect(() => {
    link()
  }, [])

  return (
    <S.Container>
      <S.ContainerHeader>
        <S.Content>
          <S.BoxHeander>
            <S.EstructurePage
              title={title}
              titlePt={titlePt}
              subtitlePt={subTitlePt}
              subtitle={subTitle}
              description={decriptionOne}
              descriptionPt={decriptionOnePt}
              descriptionTwo={decriptionTwo}
              descriptionTwoPt={decriptionTwoPt}
            >
              {i18n.language === "pt-BR" ? (
                <S.DBImageBox isResponsive>
                  <img src={imageTopPt.url} alt="Image Strategy" />
                </S.DBImageBox>
              ) : (
                <S.DBImageBox isResponsive>
                  <img src={imageTopEn.url} alt="Image Strategy" />
                </S.DBImageBox>
              )}
            </S.EstructurePage>
            {i18n.language === "pt-BR" ? (
              <S.DBImageBox>
                <img src={imageDeepTechWebPt.url} alt="Image Strategy" />
              </S.DBImageBox>
            ) : (
              <S.DBImageBox className="imagesEn">
                <img src={imageTopEn.url} alt="Image Strategy" />
                <img src={imageDownEn.url} alt="Image Strategy" />
              </S.DBImageBox>
            )}
          </S.BoxHeander>
          {i18n.language === "pt-BR" ? (
            <S.DBImageBox isResponsive>
              <img src={imageDownPt.url} alt="Image Strategy" />
            </S.DBImageBox>
          ) : (
            <S.DBImageBox isResponsive>
              <img src={imageDownMobEn.url} alt="Image Strategy" />
            </S.DBImageBox>
          )}
        </S.Content>
      </S.ContainerHeader>
      <div>
        <S.DBContentContactUsWrapper>
          <h2>
            {i18n.language === "pt-BR" ? titleContactUsPt : titleContactUs}
          </h2>
          <Button to="/contact">
            {i18n.language === "pt-BR"
              ? textButtonContactUsPt
              : textButtonContactUs}
          </Button>
        </S.DBContentContactUsWrapper>
        <S.ContentListCards>
          <S.ListCard>
            {i18n.language === "pt-BR"
              ? datas.cards.cardPt.map((item, i) => {
                  return (
                    <>
                      <S.Card key={i} id={item.id} href={item.link}>
                        <S.Title>{item.title}</S.Title>
                        <S.DescriptionCard>{item.text}</S.DescriptionCard>
                        <S.LinkKnowMore>
                          <a href={item.link}>{datas.knowMorePt}</a>
                          <img src={iconPlus.url} alt="Icon Plus" />
                        </S.LinkKnowMore>
                      </S.Card>
                    </>
                  )
                })
              : datas.cards.card.map((item, i) => {
                  return (
                    <>
                      <S.Card key={i} id={item.id} href={item.link}>
                        <S.Title>{item.title}</S.Title>
                        <S.DescriptionCard>{item.text}</S.DescriptionCard>
                        <S.LinkKnowMore>
                          <a href={item.link}>{datas.knowMore}</a>
                          <img src={iconPlus.url} alt="Icon Plus" />
                        </S.LinkKnowMore>
                      </S.Card>
                    </>
                  )
                })}
          </S.ListCard>
        </S.ContentListCards>
      </div>
    </S.Container>
  )
}
