import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import CallToAction from '../components/Home/CallToAction'
import Services from '../components/Home/Services'
import Attention from '../components/Home/Attention'
import Pioneering from '../components/Pioneering'
import JourneysActive from '../components/Home/JourneyActive'
import WhoWeAre from '../components/WhoWeAre'

import defaultImageThumb from '../assets/images/1stiThumb.png'

export default function IndexPage() {
    const query = useStaticQuery(graphql`
        {
            firsti {
                callToActions {
                    title
                    titlePt
                    about
                    aboutPt
                    linkToButtonOne
                    textButtonOne
                    textButtonOnePt
                    video {
                        url
                    }
                }
                whoWeAres {
                    title
                    titlePt
                    description
                    descriptionPt
                    image {
                        url
                    }
                }
                services {
                    title
                    titlePt
                    paragraph
                    paragraphPt
                    cards
                    knowMore
                    knowMorePt
                }
                activeJourneys {
                    title
                    titlePt
                    text
                    textPt
                    textButton
                    textButtonPt
                    sulAmericaImg {
                        url
                    }
                    sirioImg {
                        url
                    }
                    laureateImg {
                        url
                    }
                    cruzeiroImg {
                        url
                    }
                    somosImg {
                        url
                    }
                    cognaImg {
                        url
                    }
                    marisaImg {
                        url
                    }
                }
                purposes {
                    title
                    paragraph
                    graphic {
                        url
                    }
                    cards
                }
                newsletters {
                    title
                    paragraph
                    titlePt
                    paragraphPt
                }
                pioneerings {
                    title
                    titlePt
                    text
                    textPt
                    alert
                    alertPt
                    buttonText
                    buttonTextPt
                    altImg
                    slider
                    textPlaceholder
                    textPlaceholderPt
                    bookImg {
                        url
                    }
                }
            }
        }
    `)
    const { firsti } = query
    return (
        <Layout seoTitle="1STi | Home" seoImage={defaultImageThumb}>
            <CallToAction data={firsti.callToActions[0]} />
            <WhoWeAre data={firsti.whoWeAres[0]} />
            <Services data={firsti.services[0]} />
            <JourneysActive data={firsti.activeJourneys[0]} />
            <Pioneering data={firsti.pioneerings[0]} />
            <Attention data={firsti.newsletters[0]} />
        </Layout>
    )
}
