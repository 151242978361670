import styled from 'styled-components'
import Wrapper from '../Wrapper'

export const Container = styled.section`
    width: 100%;
    margin-top: 11rem;
    background-color: #0b1617;
    padding-inline: 70px;
    @media (max-width: 1024px) {
        margin-top: 5rem;
        padding-inline: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }
`

export const PioneeringSection = styled(Wrapper)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    padding: 0;
    @media (max-width: 960px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 1rem;
    }
`
export const Left = styled.div`
    /* max-width: 18.688rem; */
    max-width: 310px;
    a button {
        border: solid #fff 1px;
        margin-top: 3.375rem;
        @media (max-width: 768px) {
            margin-top: 1.4rem;
        }
    }
`
export const Title = styled.h2`
    font-size: 2.875rem;
    font-family: GoshaSans;
    line-height: 50px;
    @media (max-width: 1266px) {
        font-size: 2.3rem;
    }
`
export const Text = styled.p`
    line-height: 2rem;
    margin-top: 2.25rem;
    font-size: 1.125rem;
    font-family: CabinetGrotesk;
    @media (max-width: 1266px) {
        font-size: 1rem;
    }
    @media (max-width: 768px) {
        margin-top: 1rem;
        padding-bottom: 1rem;
    }
`

export const AlertMsg = styled.p`
    line-height: 2rem;
    margin-top: 2.25rem;
    font-size: 1.125rem;
    font-family: CabinetGrotesk;
    @media (max-width: 1266px) {
        font-size: 1rem;
    }
    @media (max-width: 768px) {
        margin-block: 1rem;
    }
`

export const ImageBook = styled.img`
    width: 400px;
    height: auto;
    /* margin-left: 9.063rem; */
    filter: drop-shadow(rgb(0 200 219 / 80%) 0px 0px 99px);
    border-radius: 5px;
    animation: ${(props) => props.theme.animations.showImage} 0.3s ease-in-out;
    @media (max-width: 1440px) {
        /* max-width: 400px;
    height: 400px; */
    }
    @media (max-width: 1266px) {
        /* width: 250px; */
    }
    @media (max-width: 1060px) {
        width: 320px;
    }
    @media (max-width: 768px) {
        width: 300px;
    }
`
export const Right = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    padding-inline: 10px;
    @media (max-width: 960px) {
        flex-wrap: wrap;
        padding-inline: 0px;
        flex-direction: column;
    }
    @media (max-width: 880px) {
        /* justify-content: normal; */
        /*     flex-direction: column; */
        /* align-items: center; */
        &:nth-child(2) {
            align-items: flex-start;
        }
    }
`
export const BoxImageBook = styled.section`
    display: ${(props) => (props.isResponsive ? 'none' : 'flex')};

    @media (max-width: 1024px) {
        display: ${(props) => (props.isResponsive ? 'block' : 'none')};
    }

    @media ${(props) => props.theme.medias.lg} {
        width: 100%;
        display: ${(props) => (props.isResponsive ? 'flex' : 'none')};
        justify-content: center;
        margin: 3rem 0;
    }
`
export const Slider = styled.div`
    width: 305px;
    max-height: 375px;

    section {
        width: 300px;
    }
`
export const ButtonSlide = styled.div`
    display: flex;
    justify-content: space-between;
    width: 78px;
    img {
        width: 31px;
        height: 26px;
        &:hover {
            cursor: pointer;
        }
    }
`
export const SlideOne = styled.div`
    height: 345px;
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    transition: 0.4s;
    p {
        &:nth-child(1) {
            padding-bottom: 1rem;
        }
    }
    @media (max-width: 350px) {
        height: 400px;
    }
`

export const SliderAuthor = styled.p`
    font-size: 25px;
    letter-spacing: 0px;
    color: #fafafa;
`

export const SliderAuthorPosition = styled.p`
    font-size: 13px;
    letter-spacing: 0px;
    color: #fafafa;
    margin-bottom: 36px;
`

export const SliderAuthorText = styled.p`
    font: normal normal;
    font-size: 18px;
    font-weight: 300;
    width: 18.9rem;
    letter-spacing: 0;
    line-height: 23px;
    color: #fafafa;
    @media (max-width: 1266px) {
        font-size: 0.9rem;
        width: 14rem;
    }
    @media (max-width: 1024px) {
        font-size: 1rem;
        width: 17.8rem;
    }
    @media (max-width: 350px) {
        width: 15.8rem;
    }
`

export const ButtonDesktop = styled.button`
    height: 59px;
    padding: 1rem 1.3rem;
    font-size: 17px;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    background: #fff;
    transition: 0.4s;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: #fff;
        background: #0b1617;
    }
`
export const BoxLinkButton = styled.div`
    a {
        text-decoration: none;
    }
`
export const ContainerArrow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 328px;
    top: 0;
    right: 0;
    opacity: 1;
    z-index: 12;
    transition: all 0.3s ease-in-out;
`
export const ArrowButton = styled.button`
    border: none;
    cursor: pointer;
    z-index: 13;
    background: transparent;

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    &:active {
        img {
            transform: scale(0.8);
        }
    }

    img {
        transition: all 0.3s ease-in-out;
        width: 31px;
        height: 26px;
    }
`

export const ArrowButtonLeft = styled.button`
    border: none;
    cursor: pointer;
    background: transparent;

    display: flex;
    justify-content: space-between;

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    &:active {
        img {
            transform: scale(0.8);
        }
    }

    img {
        transition: all 0.3s ease-in-out;
        width: 31px;
        height: 26px;
        background: transparent;
    }
`
