import React from 'react'

//components
import ImagesCarrosel from '../ImagesCarrosel'
import { ContentCover } from '../ContentCover'

// i18
import { i18n } from "../../translate/i18n"

//styles
import * as S from './styled'

import IconAnimation1sti from '../IconAnimation1sti'
import { DateSharePrint } from '../DateSharePrint'
import { ArticleSelect } from '../ArticleSelect'

const ArticleTypeTwo = ({ pageContext }) => {

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    return (
        <S.Container>
            <ContentCover
                backgroundCover={pageContext?.backgroundCover?.url}
                background={pageContext?.backgroundCoverGradient}
                backgroundMobile={pageContext?.backgroundMobile?.url}
                title={pageContext?.titleArticle}
                titleEn={pageContext?.titleArticleEn}
                buttonOne={pageContext?.buttons[0]?.label}
                buttonTwo={pageContext?.buttons[1]?.label}
            />

            <div>
                <ArticleSelect 
                    date={pageContext?.dateArticle}
                    author={pageContext?.author}
                    linkLinkedin={pageContext?.linkLinkedin}
                    title={pageContext?.titleArticle}
                    titleEn={pageContext?.titleArticleEn}

                />

                <S.BoxContent>
                    <S.ContainerShare>
                        <DateSharePrint
                            date={pageContext?.articleDate}
                            dateEn={pageContext?.articleDataEn}
                        />
                    </S.ContainerShare>

                    <S.Content
                        dangerouslySetInnerHTML={{
                            __html: isLanguangePt ? pageContext?.contentBeforeCarrosel?.html : pageContext?.contentBeforeCarroselEn?.html
                        }}
                    />
                </S.BoxContent>

                <ImagesCarrosel images={pageContext?.imgsCarousel} />

                <S.BoxContent>
                    <S.Content
                        dangerouslySetInnerHTML={{
                            __html: isLanguangePt ? pageContext?.contentAfterCarrosel?.html : pageContext?.contentAfterCarroselEn?.html
                        }}
                    />
                </S.BoxContent>

                {/* {pageContext?.videoArticle?.url && (
                    <VideoComponent
                        controls={false}
                        src={pageContext?.videoArticle?.url}
                    />
                )} */}
                <S.BoxContent>
                    <S.Content>
                        <DateSharePrint
                            hasDate={false}
                        />
                        {pageContext?.credits && (
                            <S.BoxCredit>
                                <h5>{isLanguangePt ? 'CRÉDITOS' : 'CREDITS'}</h5>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: isLanguangePt ? pageContext.credits?.html : pageContext?.creditsEn?.html
                                    }}
                                ></span>
                            </S.BoxCredit>
                        )}
                    </S.Content>
                        <IconAnimation1sti />
                </S.BoxContent>
            </div>
        </S.Container>
    )
}

export default ArticleTypeTwo
