import styled from 'styled-components'

export const ContainerServices = styled.a`
    width: 100%;

    padding-inline: 70px;
    padding-block: 50px;

    text-decoration: none;
    color: #fff;
    transition: ease 0.5s;

    cursor: pointer;
    background-color: #000;

    &:first-child{
        #titlePt{
            width: min-content;
        }
    }
    


    :hover {
        box-shadow: inset 0px 0px 70px #af39e6;

        p {
            color: rgb(56, 238, 255);
        }
    }

    @media (max-width: 1030px) {
        width: 100%;
        max-width: initial;
        flex: 1;
    }

    @media (max-width: 1024px) {
        padding-inline: 45px;
    }

    @media (max-width: 690px) {
        padding-inline: 23px;
    }
`

export const ContentServices = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const BoxTexts = styled.div`
    width: 100%;
`

export const TitleServices = styled.h2`
    width: 100%;

    font-size: 30px;
    font-weight: 700;

    margin-top: 10px;
    margin-bottom: 36px;

    max-width: 250px;

    @media (max-width: 800px) {
        font-size: 25px;
        margin-bottom: 25px;
        margin-top: 0px;
    }

    @media (max-width: 350px) {
        font-size: 16px;
    }
`

export const TitleServicesEnglish = styled.h2`
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 36px;
    width: fit-content;

    @media (max-width: 1100px) {
        max-width: 100%;
    }

    @media (max-width: 800px) {
        font-size: 25px;
        margin-bottom: 25px;
        margin-top: 0px;
        max-width: 100%;
    }

    @media (max-width: 350px) {
        font-size: 16px;
    }
`

export const Description = styled.p`
    width: 100%;
    max-width: 300px;

    font-family: CabinetGrotesk;
    font-size: 18px;
    

    font-weight: 400;
    margin-bottom: 100px;

    color: #7e7e7e;

    @media (max-width: 800px) {
        margin-bottom: 30px;
    }

    @media (max-width: 690px) {
        font-size: 1rem;
    }
`

export const LinkKnowMore = styled.div`
    display: flex;
    gap: 16px;

    img {
        width: 19px;
        cursor: pointer;
        @media (max-width: 768px) {
            width: 15px;
        }
    }
    a {
        color: #af39e6;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    @media (max-width: 690px) {
        gap: 10px;

        a {
            font-size: 1rem;
        }
    }
`
