import React, { useState, useEffect } from "react"

// i18
import { i18n } from "../../translate/i18n"

// Assets
import Linkedin from '../../assets/images/linkedin.svg'

// Styles 
import * as S from './styles'

export function ArticleSelect({ title, titleEn, author, linkLinkedin }) {
    const [scrolled, setScrolled] = useState(false)

    // Verificar se está em portugues
    const isLanguangePt = i18n.language === "pt-BR"

    useEffect(() => {
        function handleScroll() {
            setScrolled(window.scrollY > 582)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <S.ArticleSelect visible={scrolled}>
            <div>
                <h3>{isLanguangePt ? title : titleEn}</h3>
                {author && (
                    <a href={linkLinkedin} target="_blank">
                        {isLanguangePt ? 'Por' : 'By'} {author}
                        <img src={Linkedin} alt="logo linkendin" />
                    </a>
                )}
            </div>
        </S.ArticleSelect>
    )
}