import React from "react"
import Layout from "../components/Layout"
import Cover from "../components/CoverPage"

const CoverPage = () => (
  <Layout seoTitle="Capa">
    <Cover />
  </Layout>
)

export default CoverPage
