import styled from 'styled-components'
import { Wrapper } from '../../styles/Wrapper'

export const GlobalFonts = styled.div`
    @font-face {
        font-family: 'FontCabiniteGrotesk';
        src: url('https://fonts.com/myfont.woff2') format('woff2'),
            url('https://fonts.com/myfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 70px;

    width: 100%;
    height: 100%;
    background-color: #fff;
`

export const Title = styled.div`
    font-family: 'RocGroteskCompressed';
    font-size: 52px;
    font-weight: bold;
    color: #000;
    padding: 1rem 0 1rem 0;
    z-index: 11;
`

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const ContainerShare = styled.div`
    height: 100px;
    width: 80%;
    max-width: 920px;

    padding: 0.938rem 2.438rem 1.5rem 8rem;
    margin-bottom: 50px;

    z-index: 11;
    color: #000;

    @media (max-width: 540px) {
        width: 80%;
        padding: 1rem;
    }

    @media (max-width: 450px) {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 100%;

    }

`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 920px;
    padding: 0.938rem 2.438rem 1.5rem 8rem;
    z-index: 11;

    p {
        font-family: 'CabinetGrotesk';
        font-weight: 400;
        color: #0b1617;
    }

    .paragraph-initial {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h2 {
            font-family: 'CabinetGrotesk';
            font-size: 32px;
            font-weight: 600;
            color: #0b1617;
        }

        p {
            font-size: 27px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 30px;
        }
    }

    & > img {
        width: 100%;
        max-width: 407px;
        height: auto;
        max-height: 328px;
        margin: 30px 0;
        background-color: red;

        @media (max-width: 420px) {
            width: 100%;
        }
    }

    & > p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        margin-top: 30px;
        line-height: 29px;
    }

    .quotes {
        max-width: 280px;
        margin-top: 30px;
        margin-bottom: 30px;

        p {
            text-align: left;
            font-family: 'GoshaSans';
            font-weight: 600;
            font-size: 30px;
            color: #000;
            letter-spacing: 0;
        }
    }

    .second-blockquote {
        max-width: 320px;
    }

    .paragraph-xs {
        font-size: 18px;
        font-weight: 400;
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 29px;
    }

    @media (max-width: 540px) {
        display: flex;
        flex-direction: column;
        padding-block: 1rem;
        padding-inline: 0;

        .paragraph-initial {
            h2 {
                font-size: 25px;
            }

            p {
                font-size: 20px;
            }
        }

        & > p {
            font-size: 16px;
            width: 100%;
            max-width: 600px;
            line-height: 29px;
        }

        .quotes {
            width: 300px;

            p {
                text-align: left;
                font-family: 'GoshaSans-Bold';
                font-size: 30px;
            }
        }
    }
`
export const ArticleImage = styled.img`
    width: 407px;
    height: 328px;
    margin: 30px 0;

    @media (max-width: 420px) {
        width: 100%;
    }
`

export const BoxCredit = styled.section`
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 200px;
    width: 100%;
    margin-bottom: 1.625rem;
    margin-top: 5rem;

    h5 {
        font-family: 'CabinetGrotesk';
        font-size: 18px;
        letter-spacing: 0.8px;
        color: #171717;
        font-weight: 600;
    }

    span {
        p {
            font-family: 'CabinetGrotesk';
            font-weight: 400;
        }
    }

    @media (max-width: 540px) {
        height: 390px;
        h5 {
            max-width: 300px;
            font-family: 'GoshaSans-Bold';
            font-size: 20px;
            letter-spacing: 0.8px;
            color: #171717;
        }
    }
`

export const BoxSharePrint = styled.div`
    display: flex;
    gap: 20px;
`

export const Credits = styled.div`
    p {
        font-family: 'CabinetGrotesk';
        font-size: 20px;
        line-height: 1.5;

        @media (max-width: 540px) {
            font-size: 18px;
            max-width: 290px;
        }
    }
`

export const ContainerArticle = styled(Wrapper)`
    height: 300px;
`
